import { AUTH_API_ENDPOINT, checkAPIResponse } from './apiConstants';
//var request = require('request');
const BASE_URL = process.env.REACT_APP_API_URL;

const API_URL = `${BASE_URL}${AUTH_API_ENDPOINT}`;

const authApiService = {
  signin: async (newItem) => {
    try { 
        const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
            body: JSON.stringify(newItem),
        });
        const data = await response.json();
        if(checkAPIResponse(data))
        {
            localStorage.setItem('accessToken', data.results.data.accessToken);
            localStorage.setItem('email', data.results.data.email);
            console.log('Login successful!!');
            return data;
        } else {
            console.error('Error fetching data:', data.message);
            throw data.message;
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
  },

  logout: async () => {
    localStorage.removeItem('accessToken');
  },
}

export default authApiService;