export const FILTER_ENDPOINT = '/api/organization/filter';
export const USER_FILTER_ENDPOINT = '/api/webadmin/user/filter';
export const WEBADMIN_API_ENDPOINT = '/api/webadmin';
export const AUTH_API_ENDPOINT = '/api/auth/signin_webadmin';
export const OUTLET_API_ENDPOINT = '/api/outlet';
export const OUTLET_GET_API_ENDPOINT = '/api/get_outlet';
export const USER_API_ENDPOINT = '/api/webadmin/user';
export const HEAD_OFFICE_ENDPOINT = '/api/head_office';

export const checkAPIResponse = (data) => {
    return data.code === 200 ? true : false;
}
