import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete';
import UserDialog from './userDailog';
import CircularProgress from '@mui/material/CircularProgress';
import userApiService from '../services/user.apiservice';
import { removeCountryCode } from '../services/utilities';
import { useNotification } from './Notification';
import { Link } from 'react-router-dom';
import { DataGrid, GridToolbar, getGridStringOperators } from '@mui/x-data-grid';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  root: {
    '& .MuiDataGrid-footerContainer': {
      display: 'none',
    },
  },
});


const Usertable = ({ reload }) => {
  const classes = useStyles();
  const displayNotification = useNotification();
  // const [reload,setReload] = useState(reloadTable);
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(-1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [users, setUsers] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isfilter, setIsfilter] = useState(false)
  const [filterModelState, setFilterModelState] = useState()
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [editedUser, setEditedUser] = useState({
    name: '',
    phone: '',
    email: '',
    // countryCode: '+91',
    userOrganization: [],
    is_active: true,
    profile: null,
    originType: 'external'
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setCurrentPage(-1);
    setUsers([]);
  };

  const handleOpenEditModal = (user) => {
    console.log(user);
    setEditedUser(user);
    setIsEditModalOpen(true);
  };

  const handleUpdateUser = async (id, editedUser) => {
    try {
      const result = await userApiService.updateItem(id, editedUser);
      displayNotification('User updated successfully', 'success');


      getAllUser(page, rowsPerPage, true);
      handleCloseEditModal();
    } catch (error) {
      displayNotification('An error occurred', 'error');

      console.error("Error Occured while updating WebAdmin : ", error);
    }
  };

  const handleCloseEditModal = () => {
    setEditedUser({
      name: '',
      phone: '',
      email: '',
      // countryCode: '+91',
      userOrganization: [],
      is_active: true,
      profile: null,
      originType: 'external'
    });
    setIsEditModalOpen(false);
  };

  function removeLastNElements(arr, n) {
    if (n >= 0) {
      arr.splice(-n, n);
    } else {
      console.error("Invalid value of N. N should be a non-negative integer.");
    }
  }

  const onDelete = async (id) => {
    try {
      console.log("while deleteing checking id", id);
      const result = await userApiService.deleteUser(id);
      displayNotification('User Deleted Succesfully', 'sucess');

      getAllUser(page, rowsPerPage, true);
    } catch (error) {
      displayNotification(`Error Occured when deleting user :${error}`, 'error');


      console.error("Error Occured when deleting user : ", error);
    }
  }

  const getAllUser = async (page, rowsPerPage, isForceCall = false) => {
    if (isfilter != true) {
      setLoading(true);
      try {
        const result = await userApiService.getAllUsers((page + 1), rowsPerPage, "", "");
        const x = await result.results.data.userData.map((item, i) => {
          let ownerName = ""
          if (item.ownerName != null && item.ownerName != undefined) {
            ownerName = item.ownerName
          }
          return { ...item, i: ++i, id: item._id, phone: item.phone }
        })
        console.log(x);
        var data = [];
        setUsers(x);
        setTotalCount(result.results.data.totalUsers);
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
        setCurrentPage(page);
      }

    } else {
      await filterUserApi(filterModelState)
    }
  };


  const filterUserApi = async (filterModel) => {
    if (filterModel && filterModel.items && filterModel.items.length > 0) {
      let fieldKey = "";
      let filterValue = "";

      let filterItem = filterModel.items[0];
      if (filterItem && filterItem.field) {
        fieldKey = filterItem.field;
      }
      if (filterItem && filterItem.value !== undefined) {
        filterValue = filterItem.value;
      }

      const result = await userApiService.getAllUsers((page + 1), rowsPerPage, fieldKey, filterValue);
      console.log("filterresult", result);
      const x = result.results.data.userData.map((item, i) => {
        return {
          ...item, i: ++i, id: item._id
        }
      })
      console.log(x);
      setUsers(x);
      setTotalCount(result.results.data.totalUsers);
    } else {
      setFilteredUsers([]);
    }
  }


  const onFilterChange = useCallback(async (filterModel) => {
    setIsfilter(true)
    setFilterModelState(filterModel)
    await filterUserApi(filterModel)
  }, [page, rowsPerPage]);

  let columns = [
    { field: "i", headerName: "ID", width: 65, filterable: false },
    {
      field: "profile", headerName: "Profile", width: 80, filterable: false,
      renderCell: (params) => (
        <div style={{ marginTop: 10 }}>
          <img src={params.value} alt="img" style={{ width: 30, height: 30, borderRadius: '50%' }} />
        </div>
      ),
    },
    { field: "name", headerName: "Name", flex: 1, },
    { field: "email", headerName: "Email", flex: 1, },
    { field: "phone", headerName: "Mobile Number", flex: 1, },
    { field: "is_active", headerName: "Active", flex: 1, },
    { field: "originType", headerName: "User Origin", flex: 1, },
    {
      field: "actions", headerName: "Actions", width: 120, filterable: false,
      renderCell: (user) => (

        <div className={classes.actionsContainer}>
          <IconButton color="primary" onClick={() => {
            // user.phone = removeCountryCode(user.phone, user.countryCode);
            console.log(user.row);
            handleOpenEditModal(user)
          }}>
            <EditIcon />
          </IconButton>
          <IconButton color="secondary" onClick={() => onDelete(user.id)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  columns = React.useMemo(
    () =>
      columns.map((col) => {
        return {
          ...col,
          filterOperators: getGridStringOperators().filter(
            (operator) => operator.value === 'contains'
            // || operator.value === 'equals',
          ),
        };
      }),
    [columns],
  );

  // useEffect(() => {
  //   console.log("useeffect is running because of isfilter");
  //   getAllUser(page, rowsPerPage, true);
  //   }, [isfilter]);

  useEffect(() => {
    console.log("useeffect is running because of page, rowsPerPage");
    getAllUser(page, rowsPerPage);
  }, [page, rowsPerPage]);

  useEffect(() => {
    console.log("useeffect is running because of currentPage");
    getAllUser(page, rowsPerPage, true);
  }, [currentPage]);

  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : (
        <div
          className={classes.root}
          style={{ height: 515, width: '100%' }}>
          {/* <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="user table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Mobile Number</TableCell>
                  <TableCell>Email Id</TableCell>
                  <TableCell>Outlets</TableCell>
                  <TableCell>isActive</TableCell>
                  <TableCell>Profile Image</TableCell>
                  <TableCell>Actions</TableCell>
                  <TableCell>User Origin</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  // {users.map((user) => (
                  <TableRow key={user._id}>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.phone}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      <pre>{user.userOrganization.join('\n')}</pre>
                    </TableCell>
                    <TableCell>{user.is_active ? "Yes" : "No"}</TableCell>
                    <TableCell>
                     // Assuming user.logoImage is the URL of the image
                      <img src={user.profile} alt="User Profile Image" style={{ width: '50px', height: '50px' }} />
                    </TableCell>
                    <TableCell>
                      <div className={classes.actionsContainer}>
                        <IconButton color="primary" onClick={() => {
                          // user.phone = removeCountryCode(user.phone, user.countryCode);
                          handleOpenEditModal(user)
                        }}>
                          <EditIcon />
                        </IconButton>
                        <IconButton color="secondary" onClick={() => onDelete(user._id)}>
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </TableCell>
                    <TableCell>{user.originType || "external"}</TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> */}
          <DataGrid
            rows={users}
            pageSizeOptions={[5, 10, 25, 50]}
            columns={columns}
            filterMode="server"
            onFilterModelChange={onFilterChange}
            slots={{ toolbar: GridToolbar }}
            pagination={false}
          />
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <UserDialog open={isEditModalOpen} onClose={handleCloseEditModal} onSave={handleUpdateUser} user={editedUser} isAdding={false} />
        </div>
      )}
    </div>
  );
};

export default Usertable;