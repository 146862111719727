import React, { useState, useEffect } from 'react';
import Outlettable from '../components/Outlettable';
import Button from '@mui/material/Button';
import Appbar from '../components/Appbar';
import OutletDialog from '../components/Outletdailog';
import outletApiService from '../services/outlet.apiservice';
import { useNotification } from '../components/Notification';
import { Link } from 'react-router-dom'; // Import useHistory hook
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';


const Outlets = () => {
  // const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const displayNotification = useNotification();
  // const history = useHistory(); // Initialize useHistory hook

const [key,setKey]=useState(false);
  const [newOutlet, setNewOutlet] = useState({
    name: '',
    ownerName: '',
    ownerMobileNumber: '',
    ownerEmail: '',
    address: '',
    zip_code: '',
    city: '',
    state: '',
    country: 'india',
    category: 'restaurant',
    gstNumber: '',
    email:'',
    phone:'',
    subscriptionEndDate: new Date(new Date().setMonth(new Date().getMonth() + 1)), 
    is_active: true,
    profile: null,
    originType:'external',
    hasPettpoojaID:false,
    petpoojaID:''
  });

  const handleReloadPage = () => {
    window.location.reload();
  };

  // const handleOpenAddModal = () => {
  //   setIsAddModalOpen(true);
  // };

  const redirectToForm = () => {
    // history.push('/add-outlet');
  };

    const reloadOutletTable=()=>{
      setKey(true);
    }
  const handleAddOutlet = async (newOutlet) => {
    try {
      const result = await outletApiService.createOutlet(newOutlet);
      handleCloseAddModal();
      reloadOutletTable();
      displayNotification('Outlet created successfully', 'success');



      // handleReloadPage();
    } catch (error) {
      console.error("Error Occured while create Outlet : ", error);
      displayNotification('Error Occured while create Outlet', 'error');

    }
    handleCloseAddModal();
  };

  const handleCloseAddModal = () => {
    setNewOutlet({
        name: '',
        ownerName: '',
        ownerMobileNumber: '',
        ownerEmail: '',
        address: '',
        zip_code: '',
        city: '',
        state: '',
        country: 'india',
        category: 'restaurant',
        gstNumber: '',
        email:'',
        phone:'',
        subscriptionEndDate: new Date(new Date().setMonth(new Date().getMonth() + 1)), 
        is_active: true,
        profile: null,
        originType:'external',
        hasPettpoojaID:false,
    petpoojaID:''
      });
  };

  return (
     <div>
        {/* <Appbar title="Outlets"/> */}
        <div style={{margin: '5px'}}>  
        <div style={{textAlign: 'end'}}>
        <Link to="/outlets/add" style={{ textDecoration: 'none' }}>
            <Button
              color="primary"
              variant="contained"
              style={{ marginBottom: '10px' }}
            >
              ADD OUTLET
            </Button>
          </Link>

        </div>
        <Outlettable reload={key} />
        </div>
        {/* <OutletDialog  onSave={handleAddOutlet} outlet={newOutlet} isAdding={true}/> */}

    </div>
  );
};

export default Outlets;
