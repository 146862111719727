import {  USER_API_ENDPOINT, checkAPIResponse, USER_FILTER_ENDPOINT } from './apiConstants';
import { trimInputValue } from '../services/utilities';
const BASE_URL = process.env.REACT_APP_API_URL;

const API_URL = `${BASE_URL}${USER_API_ENDPOINT}`;
// const USER_FILTER = `${BASE_URL}${USER_FILTER_ENDPOINT}`;

const userApiService = {
  getAllUsers: async (page, pageSize, field_key, value) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch(`${API_URL}?page=${page}&pageSize=${pageSize}&field_key=${field_key}&value=${value}`, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': accessToken,
        },
      });
      const data = await response.json();
      if (checkAPIResponse(data)) {
        console.log("getAllUsers API Response Successful!");
        return data;
      } else {
        console.error('Error fetching data:', data.message);
        throw data.message;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  },

  userFilter: async (newItem, page, pageSize) => {
    try {
      console.log(newItem);
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch(`${USER_FILTER_ENDPOINT}/?page=${page}&pageSize=${pageSize}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': accessToken,
        },
        body: JSON.stringify(newItem),
      });
      const data = await response.json();
      if (checkAPIResponse(data)) {
        console.log("Outlet Filter API Response Successful!");
        return data;
      } else {
        console.error('Error: ', data.message);
        throw data.message;
      }
    } catch (error) {
      console.error('Error: ', error);
      throw error;
    }
  },



  deleteUser: async (itemId) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      console.log(itemId);
      const response = await fetch(`${API_URL}/${itemId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': accessToken,
        },
      });

      const data = await response.json();

      if (checkAPIResponse(data)) {
        console.log("Deleted User Successful!");
        return data;
      } else {
        console.error('Error Deleting WebAdmin:', data.message);
        throw data.message;
      }
    } catch (error) {
      console.error('Error Deleting WebAdmin:', error);
      throw error;
    }
  },

  updateItem: async (itemId, updatedItem) => {
    try {
      console.log(itemId);
      const accessToken = localStorage.getItem('accessToken');
      var formdata = new FormData();
      for (const key in updatedItem) {
        if (key !== "profileImage") {
          formdata.append(key, trimInputValue(updatedItem[key]));
        }
      }
      formdata.append('profileImage', '');
      formdata.append('userId', itemId);

      const response = await fetch(`${API_URL}`, {
        method: 'PUT',
        headers: {
          //'Content-Type': 'application/json',
          'x-access-token': accessToken,
        },
        body: formdata,
      });
      const data = await response.json();
      if (checkAPIResponse(data)) {
        console.log("Update User API Response Successful!");
        return data;
      } else {
        console.error('Error: ', data.message);
        throw data.message;
      }
    } catch (error) {
      console.error('Error: ', error);
      throw error;
    }
  },

  createUser: async (newItem) => {
    var jsonData = {};
    for (const key in newItem) {
      jsonData[key] = trimInputValue(newItem[key]);
    }
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': accessToken,
        },
        body: JSON.stringify(jsonData),
      });
      const data = await response.json();
      if (checkAPIResponse(data)) {
        console.log("Create User API Response Successful!");
        return data;
      } else {
        console.error('Error: ', data.message);
        throw data.message;
      }
    } catch (error) {
      console.error('Error: ', error);
      throw error;
    }
  },
}

export default userApiService;