import React, { useState, useEffect } from 'react';
import WebAdmintable from '../components/webAdminTable';
import Button from '@mui/material/Button';
import WebAdminDialog from '../components/webAdminDailog';
import webAdminApiService from '../services/webadmin.service';
import { useNotification } from '../components/Notification';


const WebAdmin = () => {
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [key,setKey]= useState(false);
    const displayNotification = useNotification();

    const [newAdminUser, setNewAdminUser] = useState({
        name: '',
        phoneNumber: '',
        email: '',
        password: '',
        isActive: true,
        roles: [], 
        profileImage: null,
    });
    
    const reloadWenadminTable=()=>{
      setKey(true);
    }

    const handleReloadPage = () => {
      window.location.reload();
    };

    const handleOpenAddModal = () => {
      setIsAddModalOpen(true);
    };
  
    const handleAddAdmin = async (adminUser) => {
      try {
        const result = await webAdminApiService.createWebAdmin(adminUser);
       
        
        handleCloseAddModal();
        reloadWenadminTable();
        displayNotification('Webadmin created Successfully', 'sucess');
        // handleReloadPage();
      } catch (error) {
       
        displayNotification('Error Occured while create web admin', 'error');

        console.error("Error Occured while create web Admin : ", error);
      }
    };
  
    const handleCloseAddModal = () => {
      setNewAdminUser({
          name: '',
          phoneNumber: '',
          email: '',
          password: '',
          isActive: true,
          roles: [], 
          profileImage: null,
        });
        setIsAddModalOpen(false);
    };
  
    return (
        <div>
          {/* <Appbar title="WebAdmin"/> */}
          <div style={{margin: '5px'}}>  
          <div style={{textAlign: 'end'}}>
            <Button
                color="primary"
                variant="contained"
                style={{ marginBottom: '10px' }}
                onClick={handleOpenAddModal}
              >
                ADD ADMIN
            </Button>
          </div>
          <WebAdmintable reload={key} />
          </div>
          <WebAdminDialog open={isAddModalOpen} onClose={handleCloseAddModal} onSave={handleAddAdmin} webadmin={newAdminUser} isAdding={true}/>

          </div> 
    );
  };
  
  export default WebAdmin;