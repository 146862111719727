import { configureStore } from "@reduxjs/toolkit";
import { hoApi } from "./hoApi";


const reduxStore = configureStore({
    reducer: {
        [hoApi.reducerPath]: hoApi.reducer,
    },
    middleware: def => [...def(), hoApi.middleware]
})

export default reduxStore