import React, { useState, useEffect } from "react";
import { FormControl, FormLabel } from "@mui/material";
import countriesData from "./countryJson.json";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { FormHelperText } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Link, Outlet } from "react-router-dom"; // Import useHistory hook
import outletApiService from "../services/outlet.apiservice";
import { useNotification } from "../components/Notification";
import { useNavigate } from "react-router-dom";
import MuiPhoneNumber from "material-ui-phone-number";
import ReactPhoneInput from "react-phone-input-material-ui";
import PhoneNumberInput from "material-ui-phone-number";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useGetAllHeadOfficeQuery } from "../services/redux/hoApi";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
// import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import ReactSelect from "react-select";
import OutlinedInput from "@mui/material/OutlinedInput";
import ClearIcon from "@mui/icons-material/Clear";
import MenuItem from "@mui/material/MenuItem";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {
  isNotValidOutletMobileNumber,
  isNotValidMobileNumber,
  industryTypes,
  isNotValidEmail,
  isNotFutureDate,
} from "../services/utilities";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { WrapText } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import hoApiService from "../services/ho.service";

const OutletDialog = ({ isAdding }) => {
  const [selectedCountry, setSelectedCountry] = useState("India");
  const [selectedState, setSelectedState] = useState("Select State");
  const [selectedCity, setSelectedCity] = useState("Select City");
  const [outlets, setOutlets] = useState([]);
  const [hasPetpoojaId, setHasPetpoojaId] = useState("no");
  const [petpoojaId, setPetpoojaId] = useState("");

  const handleToggle = (event, newValue) => {
    setHasPetpoojaId(newValue);
    newValue === "no" && handleFieldChange("petpoojaID", "");
    handleFieldChange("hasPettpoojaID", newValue);
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    setSelectedState("");
    setSelectedCity("");
  };

  const handleStateChange = (value) => {
    setSelectedState(value);
    setSelectedCity("");
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
  };

  const { id } = useParams();
  console.log(id);
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(-1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [countryOptions, setCountryOptions] = useState([]);

  const [diffEditedOutlet, setDiffEditedOutlet] = useState({});
  const [newOutlet, setNewOutlet] = useState({
    name: "",
    hoId: "",
    // ownerName: "",
    // ownerMobileNumber: "",
    // ownerEmail: "",
    address: "",
    zip_code: "",
    city: "",
    state: "",
    country: "",
    category: "restaurant",
    gstNumber: "",
    email: "",
    phone: "",
    subscriptionEndDate: new Date(
      new Date().setMonth(new Date().getMonth() + 1)
    ),
    is_active: true,
    profile: null,
    originType: "external",
    hasPettpoojaID: "no",
    petpoojaID: "",
  });
  const removeHyphenFromNumber = (number) => {
    // Remove all spaces and hyphens
    return number.replace(/[\s-]+/g, "");
  };

  const [editedOutlet, setEditedOutlet] = useState({ ...newOutlet });

  const navigate = useNavigate();

  const displayNotification = useNotification();

  const [errors, setErrors] = useState({
    name: "",
    city: "",
    state: "",
    country: "India",
    zip_code: "",
    email: "",
    phone: "",
    // ownerMobileNumber: "",
    // ownerEmail: "",
    subscriptionEndDate: "",
    hasPettpoojaID: "",
    petpoojaID: "",
  });

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      name: "",
      city: "",
      state: "",
      zip_code: "",
      email: "",
      country: "",
      phone: "",
      // ownerMobileNumber: "",
      // ownerEmail: "",
      subscriptionEndDate: "",
      petpoojaID: "",
      hasPettpoojaID: "",
    };

    const countryCode = [
      "+91",
      "+93",
      "+1",
      "+7",
      "+20",
      "+27",
      "+30",
      "+31",
      "+32",
      "+33",
      "+34",
      "+36",
      "+39",
      "+40",
      "+41",
      "+43",
      "+44",
      "+45",
      "+46",
      "+47",
      "+48",
      "+49",
      "+51",
      "+52",
      "+53",
      "+54",
      "+55",
      "+56",
      "+57",
      "+58",
      "+60",
      "+61",
      "+62",
      "+63",
      "+64",
      "+65",
      "+66",
      "+81",
      "+82",
      "+84",
      "+86",
      "+90",
      "+92",
      "+94",
      "+95",
      "+98",
      "+211",
      "+212",
      "+213",
      "+216",
      "+218",
      "+220",
      "+221",
      "+222",
      "+223",
      "+224",
      "+225",
      "+226",
      "+227",
      "+228",
      "+229",
      "+230",
      "+231",
      "+232",
      "+233",
      "+234",
      "+235",
      "+236",
      "+237",
      "+238",
      "+239",
      "+240",
      "+241",
      "+242",
      "+243",
      "+244",
      "+245",
      "+246",
      "+248",
      "+249",
      "+250",
      "+251",
      "+252",
      "+253",
      "+254",
      "+255",
      "+256",
      "+257",
      "+258",
      "+260",
      "+261",
      "+262",
      "+263",
      "+264",
      "+265",
      "+266",
      "+267",
      "+268",
      "+269",
      "+290",
      "+291",
      "+297",
      "+298",
      "+299",
      "+350",
      "+351",
      "+352",
      "+353",
      "+354",
      "+355",
      "+356",
      "+357",
      "+358",
      "+359",
      "+370",
      "+371",
      "+372",
      "+373",
      "+374",
      "+375",
      "+376",
      "+377",
      "+378",
      "+379",
      "+380",
      "+381",
      "+382",
      "+383",
      "+385",
      "+386",
      "+387",
      "+389",
      "+420",
      "+421",
      "+423",
      "+500",
      "+501",
      "+502",
      "+503",
      "+504",
      "+505",
      "+506",
      "+507",
      "+508",
      "+509",
      "+590",
      "+591",
      "+592",
      "+593",
      "+594",
      "+595",
      "+596",
      "+597",
      "+598",
      "+599",
      "+670",
      "+672",
      "+673",
      "+674",
      "+675",
      "+676",
      "+677",
      "+678",
      "+679",
      "+680",
      "+681",
      "+682",
      "+683",
      "+685",
      "+686",
      "+687",
      "+688",
      "+689",
      "+690",
      "+691",
      "+692",
      "+850",
      "+852",
      "+853",
      "+855",
      "+856",
      "+880",
      "+886",
      "+960",
      "+961",
      "+962",
      "+963",
      "+964",
      "+965",
      "+966",
      "+967",
      "+968",
      "+970",
      "+971",
      "+972",
      "+973",
      "+974",
      "+975",
      "+976",
      "+977",
      "+992",
      "+993",
      "+994",
      "+995",
      "+996",
      "+998",
    ];

    if (editedOutlet.name.trim() === "") {
      newErrors.name = "Name is required";
      valid = false;
    }

    if (editedOutlet.city === undefined || editedOutlet.city.trim() === "") {
      newErrors.city = "City is required";
      valid = false;
    }

    if (editedOutlet.state === undefined || editedOutlet.state.trim() === "") {
      newErrors.state = "State is required";
      valid = false;
    }

    if (editedOutlet.zip_code.trim() === "") {
      newErrors.zip_code = "Pincode is required";
      valid = false;
    } else if (!/^\d+$/.test(editedOutlet.zip_code)) {
      // Check if zip_code contains only numbers
      newErrors.zip_code = "Outlet Zip Code should contain only numbers";
      valid = false;
    }

    // if (editedOutlet.ownerMobileNumber === undefined) {
    //   newErrors.ownerMobileNumber = "Mobile Number is required";
    //   valid = false;
    // } else if (!isNotValidMobileNumber(editedOutlet.ownerMobileNumber)) {
    //   newErrors.ownerMobileNumber = "Mobile Number is invalid";
    //   valid = false;
    // }
    // else if (editedOutlet.ownerMobileNumber.trim() === "") {
    //   newErrors.ownerMobileNumber = "Mobile Number is invalid";
    //   valid = false;
    // }

    // for (let cc of countryCode) {
    //   if (!editedOutlet.ownerMobileNumber.startsWith("+91")) {
    //     if (editedOutlet.ownerEmail.trim() === "") {
    //       newErrors.ownerEmail = "Outlet Email is required";
    //       valid = false;
    //     } else if (editedOutlet.ownerEmail.trim() === "") {
    //       newErrors.ownerEmail = "Outlet Email is required";
    //       valid = false;
    //     }
    //   }
    // }

    // if (
    //   editedOutlet.ownerEmail !== undefined &&
    //   editedOutlet.ownerEmail !== null
    // ) {
    //   if (
    //     editedOutlet.ownerEmail.length > 0 &&
    //     isNotValidEmail(editedOutlet.ownerEmail) &&
    //     isAdding
    //   ) {
    //     newErrors.ownerEmail = "Email is Invalid";
    //     valid = false;
    //   }
    // }

    // if (editedOutlet.email === undefined) {
    //   newErrors.email = "Outlet Email is required";
    //   valid = false;
    // } else if (editedOutlet.email.trim() === "") {
    //   newErrors.email = "Outlet Email is required";
    //   valid = false;
    // } else if (isNotValidEmail(editedOutlet.email)) {
    //   newErrors.email = "Outlet Email is Invalid";
    //   valid = false;
    // }

    if (hasPetpoojaId === "yes") {
      if (editedOutlet.petpoojaID === undefined) {
        newErrors.petpoojaID = "Outlet petpooja restaurent id is required";
        valid = false;
      } else if (editedOutlet.petpoojaID.trim() === "") {
        newErrors.petpoojaID = "Outlet petpooja restaurent id is required";
        valid = false;
      } else if (editedOutlet.petpoojaID === null) {
        newErrors.petpoojaID = "Outlet petpooja restaurent id is required";
        valid = false;
      }
    }

    // if (editedOutlet.phone === undefined) {
    //   newErrors.phone = "Outlet Phone Number is required";
    //   valid = false;
    // } else if (editedOutlet.phone.trim() === "") {
    //   newErrors.phone = "Outlet Phone Number is required";
    //   valid = false;
    // } else if (!isNotValidOutletMobileNumber(editedOutlet.phone)) {
    //   newErrors.phone = "Outlet Phone Number is invalid";
    //   valid = false;
    // }

    if (editedOutlet.subscriptionEndDate === "") {
      newErrors.subscriptionEndDate = "Subscription End Date is required";
      valid = false;
    } else if (
      isNotFutureDate(editedOutlet.subscriptionEndDate) &&
      editedOutlet.is_active
    ) {
      console.log("Invalid Subscription date");
      newErrors.subscriptionEndDate = "Subscription End Date is Invalid";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const getOutlet = async (id) => {
    try {
      var outlet = await outletApiService.getOutletById(id);
      console.log("Outlet", outlet.results.data);
      var Outlet = outlet.results.data;
      if (Outlet.hasPettpoojaID === "yes") {
        setHasPetpoojaId("yes");
      } else if (
        Outlet.hasPettpoojaID === "no" ||
        Outlet.hasPettpoojaID === ""
      ) {
        setHasPetpoojaId("no");
      }

      setEditedOutlet({
        name: Outlet.name,
        hoId: Outlet.hoId,
        // ownerName: Outlet.ownerName || "",
        // ownerMobileNumber: Outlet.ownerMobileNumber || "",
        // ownerEmail: Outlet.ownerEmail || "",
        address: Outlet.address,
        zip_code: Outlet.zip_code,
        city: Outlet.city,
        state: Outlet.state,
        country: Outlet.country??"India",
        category: Outlet.category,
        gstNumber: Outlet.gstNumber,
        email: Outlet.email,
        phone: Outlet.phone,
        subscriptionEndDate: Outlet.subscriptionEndDate,
        is_active: Outlet.is_active,
        profile: Outlet.profile || "",
        originType: Outlet.originType || "external",
        hasPettpoojaID: Outlet.hasPettpoojaID,
        petpoojaID: Outlet.petpoojaID,
      });

      setSelectedState(Outlet.state);
      setSelectedCity(Outlet.city);
      setSelectedHo(Outlet.hoId);
      return;
    } catch (error) {
      // toast.info(`Error Occured while updating WebAdmin : ${error}`,{autoClose: 1000,
      // });

      displayNotification("Error Occured while updating Outlet", "error");

      console.error("Error Occured while updating Outlet : ", error);
    }
  };

  useEffect(() => {
    try {
      setCountryOptions(countriesData);
    } catch (error) {
      console.error("Error parsing countries data:", error);
    }

    getAllHo();
  }, [isAdding]);

  const handleFieldChange = (field, value) => {
    // removeHyphenFromNumber(editedOutlet.ownerMobileNumber);
    removeHyphenFromNumber(editedOutlet.phone);
    if (field === "country") {
      handleCountryChange(value);
    } else if (field === "state") {
      handleStateChange(value);
    } else if (field === "city") {
      handleCityChange(value);
    } else if (field === "phone") {
      value = removeHyphenFromNumber(value);
    }
    // else if (field === "ownerMobileNumber") {
    //   value = removeHyphenFromNumber(value);
    // }
    else if (field === "hasPettpoojaID") {
      setHasPetpoojaId(value);
      value === "no" && handleFieldChange("petpoojaID", "");
    }
    setEditedOutlet((prev) => ({ ...prev, [field]: value }));
    setDiffEditedOutlet((prev) => ({ ...prev, [field]: value }));
    // Clear the error message when the user starts typing
    setErrors({
      ...errors,
      [field]: "",
    });
  };

  const handleOnClose = () => {
    setErrors({
      name: "",
      city: "",
      state: "",
      zip_code: "",
      email: "",
      phone: "",
      // ownerMobileNumber: "",
      // ownerEmail: "",
      subscriptionEndDate: "",
    });
    setEditedOutlet({
      hoId: "",
      name: "",
      // ownerName: "",
      // ownerMobileNumber: "",
      // ownerEmail: "",
      address: "",
      zip_code: "",
      email: "",
      phone: "",
      city: "",
      state: "",
      country: "India",
      category: "restaurant",
      gstNumber: "",
      subscriptionEndDate: new Date(
        new Date().setMonth(new Date().getMonth() + 1)
      ),
      is_active: true,
      profile: null,
      originType: "external",
      hasPettpoojaID: "no",
      petpoojaID: "",
    });
    setDiffEditedOutlet({});
    console.log(editedOutlet);
    navigate("/outlets");
  };

  const handleAddOutlet = async (newOutlet) => {
    try {
      const result = await outletApiService.createOutlet(newOutlet);
      handleCloseAddModal();
      // reloadOutletTable();
      displayNotification("Outlet created successfully", "success");
      // handleReloadPage();
    } catch (error) {
      console.error("Error Occured while create Outlet : ", error);
      displayNotification("Error Occured while create Outlet", "error");
    }
    handleCloseAddModal();
  };

  const handleCloseAddModal = () => {
    setNewOutlet({
      name: "",
      // ownerName: "",
      // ownerMobileNumber: "",
      // ownerEmail: "",
      address: "",
      zip_code: "",
      city: "",
      state: "",
      country: "India",
      category: "restaurant",
      gstNumber: "",
      email: "",
      phone: "",
      subscriptionEndDate: new Date(
        new Date().setMonth(new Date().getMonth() + 1)
      ),
      is_active: true,
      profile: null,
      originType: "external",
      hasPettpoojaID: "no",
      petpoojaID: "",
    });
  };

  function removeLastNElements(arr, n) {
    if (n >= 0) {
      arr.splice(-n, n);
    } else {
      console.error("Invalid value of N. N should be a non-negative integer.");
    }
  }

  const getAllHo = async () => {
    setLoading(true);
    try {
      const result = await hoApiService.getAllHoNames();
      const x = result.results.data.hoData.map((item) => {
        // console.log(item);
        return { value: item._id, label: item.name };
      });
      setHoOpt(x);

      if (!isAdding) {
        getOutlet(id);
      }
      // console.log(result);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setCurrentPage(-1);
    setOutlets([]);
  };

  const handleLogoImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Validate the file extension
      const allowedExtensions = ["jpg", "jpeg", "png"];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        setEditedOutlet((prev) => ({
          ...prev,
          profile_photos_pet_pooja_task_manager: file,
        }));
        setDiffEditedOutlet((prev) => ({
          ...prev,
          profile_photos_pet_pooja_task_manager: file,
        }));

        // Generate a preview for the selected image
        const reader = new FileReader();
        reader.onloadend = () => {
          handleFieldChange("profile", reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Invalid file type. Please upload a valid image file.");
        e.target.value = "";

        // setEditedOutlet((prev) => ({ ...prev, logoImage: null }));
        //setLogoPreview('');
        // setProfilePhotoFileName(null);
      }
    } else {
      // Reset the logoImage and preview if no file selected
      handleFieldChange("profile", null);
      // e.target.value = "";

      //setEditedOutlet((prev) => ({ ...prev, logoImage: null }));
      //setLogoPreview('');
    }
  };

  const handleClearLogoImage = () => {
    handleFieldChange("profile", null);
    // setEditedOutlet((prev) => ({ ...prev, [field]: value }));
    document.getElementById("logo").value = "";

    // setEditedOutlet((prev) => ({ ...prev, profile: "" }));
    //setLogoPreview('');
  };

  const handleUpdateOutlet = async (id, editedOutlet) => {
    try {
      console.log("Diff Outlet", editedOutlet);
      const result = await outletApiService.updateItem(id, editedOutlet);
      // getAllOutlet(page, rowsPerPage, true);
      console.log("handleUpdateOutlet",result);
      displayNotification("Outlet Updated Succesfully", "sucess");
      handleCloseAddModal();
    } catch (error) {
      // toast.info(`Error Occured while updating WebAdmin : ${error}`,{autoClose: 1000,
      // });

      displayNotification(
        "Error Occured while updating outlet from edit route",
        "error"
      );

      console.error("Error Occured while updating WebAdmin : ", error);
    }
  };

  const handleSave = () => {
    console.log("Edited Outlet", editedOutlet);

    if (validateForm()) {
      if (isAdding) {
        handleAddOutlet(editedOutlet);
      } else {
        if (Object.keys(editedOutlet).length > 0) {
          handleUpdateOutlet(id, diffEditedOutlet);
        }
      }
      handleOnClose();
      navigate("/outlets");
    }
  };

  const colourStyles = {
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      display: "none",
      border: "none",
    }),
    control: (base) => ({
      ...base,
      paddingTop: "10px",
      paddingBottom: "10px",
      paddingLeft: "5px",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };

  // const { data, isError, error } = useGetAllHeadOfficeQuery();
  // console.log(data);
  const [selectedHo, setSelectedHo] = useState("");
  const [hoOpt, setHoOpt] = useState([]);

  const hoHandleChange = (selectedOptions) => {
    setEditedOutlet((prev) => ({
      ...prev,
      hoId: selectedOptions.value
    }));
    setDiffEditedOutlet((prev) => ({
      ...prev,
      hoId: selectedOptions.value
    }));
    setSelectedHo(selectedOptions.value);
  };

  // useEffect(() => {
  //   if (data != undefined && data != null && data != "") {
  //     const x = data.hoData.map((item) => {
  //       console.log(item);
  //       return { value: item._id, label: item.hoName };
  //     });
  //     setHoOpt(x);
  //   }
  // }, [data]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "block",
        margin: "30px",
      }}
    >
      {/* <pre>{JSON.stringify(editedOutlet,null,2)}</pre> */}
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      <FormControl
        style={{
          maxWidth: "1000px",
          width: "100%",
          padding: "20px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          backgroundColor: "#fff",
        }}
      >
        <FormLabel>
          {isAdding ? "Add New Outlet" : "Edit Outlet Details"}
        </FormLabel>

        <Link to="/outlets" style={{ textDecoration: "none" }}>
          <IconButton
            style={{ position: "absolute", right: "8px", top: "8px" }}
            onClick={handleOnClose}
          >
            <ClearIcon />
          </IconButton>
        </Link>

        <Stack style={{ marginBottom: "20px" }} fullWidth>
          <Divider textAlign="center">OUTLET DETAILS</Divider>

          <ReactSelect
            sx={{ zIndex: 10 }}
            styles={colourStyles}
            placeholder="Select HO"
            getOptionLabel={(depart) => depart.label}
            options={hoOpt}
            isMulti={false}
            isSearchable={true}
            value={hoOpt.filter((e) => {
              if (e.value == selectedHo) {
                console.log(e.value == selectedHo, selectedHo, e);
                return e;
              } else {
                return "";
              }
            })}
            onChange={hoHandleChange}
          />

          <TextField
            label="Outlet Name"
            value={editedOutlet.name}
            onChange={(e) => handleFieldChange("name", e.target.value)}
            variant="outlined"
            error={!!errors.name}
            helperText={errors.name}
            style={{ marginTop: "10px", marginBottom: "10px" }}
          />
          <TextField
            label="Outlet Email"
            value={editedOutlet.email || ""}
            onChange={(e) => handleFieldChange("email", e.target.value)}
            variant="outlined"
            error={!!errors.email}
            helperText={errors.email}
            style={{ marginTop: "10px", marginBottom: "10px" }}
          />
          <TextField
            label="Outlet Address"
            value={editedOutlet.address || ""}
            onChange={(e) => handleFieldChange("address", e.target.value)}
            variant="outlined"
            style={{ marginTop: "10px" }}
          />
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-start"
          // alignItems="center"
          spacing={2}
          style={{ marginBottom: "10px" }}
          sx={{ width: "100%" }}
        >
          <FormControl variant="outlined" sx={{ width: "100%", minWidth: 75 }}>
            <label
              htmlFor="state"
              style={{
                color: "#808080",
              }}
            >
              Select Country
            </label>

            <Select
              labelId="country-label"
              id="country"
              label="Select country"
              value={editedOutlet.country || selectedCountry}
              onChange={(e) => handleFieldChange("country", e.target.value)}
              input={<OutlinedInput />}
              // sx={{ width: 320 }}
            >
              <MenuItem value="">Select Country</MenuItem>
              {countryOptions.map((country) => (
                <MenuItem key={country.id} value={country.name}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" sx={{ width: "100%", minWidth: 75 }}>
            <label
              htmlFor="state"
              style={{
                color: "#808080",
              }}
            >
              Select State
            </label>

            <Select
              labelId="state-label"
              id="state"
              value={editedOutlet.state || selectedState}
              onChange={(e) => handleFieldChange("state", e.target.value)}
              input={<OutlinedInput />}
              error={!!errors.state}
              helperText={errors.state}
            >
              <MenuItem value="">Select State</MenuItem>
              {countryOptions
                .find((country) => country.name === selectedCountry)
                ?.state.map((state) => (
                  <MenuItem key={state.id} value={state.name}>
                    {state.name}
                  </MenuItem>
                ))}
            </Select>
            {errors.state && (
              <FormHelperText error>{errors.state}</FormHelperText>
            )}
          </FormControl>
          <FormControl variant="outlined" sx={{ width: "100%", minWidth: 75 }}>
            <label
              htmlFor="state"
              style={{
                color: "#808080",
              }}
            >
              Select City
            </label>

            <Select
              labelId="city-label"
              id="city"
              value={editedOutlet.city || selectedCity}
              onChange={(e) => handleFieldChange("city", e.target.value)}
              error={!!errors.city}
              helperText={errors.city}
              input={<OutlinedInput />}
              // sx={{ minWidth: 325 }}
            >
              <MenuItem value="">Select City</MenuItem>
              {countryOptions
                .find((country) => country.name === selectedCountry)
                ?.state.find((state) => state.name === selectedState)
                ?.city.map((city) => (
                  <MenuItem key={city.id} value={city.name}>
                    {city.name}
                  </MenuItem>
                ))}
            </Select>
            {errors.city && (
              <FormHelperText error>{errors.city}</FormHelperText>
            )}
          </FormControl>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-start"
          // alignItems="center"
          spacing={2}
          style={{}}
        >
          <TextField
            label="Pincode"
            value={editedOutlet.zip_code || ""}
            onChange={(e) => handleFieldChange("zip_code", e.target.value)}
            variant="outlined"
            error={!!errors.zip_code}
            helperText={errors.zip_code}
            style={{ marginTop: "10px", marginBottom: "10px" }}
            sx={{ width: 500 }}
          />
          <TextField
            label="GST Number"
            value={editedOutlet.gstNumber || ""}
            onChange={(e) => handleFieldChange("gstNumber", e.target.value)}
            variant="outlined"
            style={{ marginTop: "10px", marginBottom: "10px" }}
            sx={{ width: 500 }}
          />
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          style={{ marginTop: "10px", marginBottom: "10px", marginLeft: "8px" }}
        >
          <FormLabel style={{ color: "#808080" }}>
            Already Petpooja User?
          </FormLabel>
          <ToggleButtonGroup
            color="primary"
            value={hasPetpoojaId}
            exclusive
            onChange={(e) =>
              handleFieldChange("hasPettpoojaID", e.target.value)
            }
            aria-label="Do you have a Petpooja ID?"
          >
            <ToggleButton style={{ color: "black", height: "70%" }} value="yes">
              Yes
            </ToggleButton>
            <ToggleButton style={{ color: "black", height: "70%" }} value="no">
              No
            </ToggleButton>
          </ToggleButtonGroup>

          {hasPetpoojaId === "yes" && (
            <TextField
              label="Petpooja ID"
              value={editedOutlet.petpoojaID || petpoojaId}
              onChange={(e) => handleFieldChange("petpoojaID", e.target.value)}
              error={!!errors.petpoojaID}
              helperText={errors.petpoojaID}
              sx={{ width: 500 }}
              variant="outlined"
            />
          )}
        </Stack>

        <Stack
          style={{ marginBottom: "10px", marginTop: "10px", width: "100%" }}
        >
          <FormLabel id="industry-type-label" style={{ color: "#808080" }}>
            Industry Type
          </FormLabel>

          <Select
            labelId="industry-type-label"
            id="industry-type"
            value={editedOutlet.category}
            onChange={(e) => handleFieldChange("category", e.target.value)}
            style={{ margin: "5px" }}
            input={<OutlinedInput />}
          >
            {industryTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>

          <FormLabel htmlFor="logo" style={{ color: "#808080" }}>
            Logo
          </FormLabel>
          <TextField
            type="file"
            id="logo"
            onChange={handleLogoImageChange}
            variant="outlined"
            style={{ margin: "5px" }}
          />
          {/* Logo preview and clear button */}
          {editedOutlet.profile && (
            <div>
              <img
                src={editedOutlet.profile}
                alt="Logo Preview"
                style={{ maxWidth: "100%", marginTop: "10px" }}
              />
              <Button
                onClick={handleClearLogoImage}
                color="secondary"
                variant="outlined"
                style={{ marginTop: "10px" }}
              >
                Clear Logo
              </Button>
            </div>
          )}
        </Stack>
        <Stack style={{ marginBottom: "10px" }}>
          {/* <Divider textAlign="center">POC DETAILS</Divider>

          <TextField
            disabled={isAdding ? false : true}
            label="POC Name"
            value={editedOutlet.ownerName || ""}
            onChange={(e) => handleFieldChange("ownerName", e.target.value)}
            variant="outlined"
            style={{ marginTop: "10px", marginBottom: "10px" }}
          />
          <PhoneNumberInput
            autoFormat={false}
            defaultCountry="in" // Default country code for India
            onChange={(value) => handleFieldChange("ownerMobileNumber", value)}
            variant="outlined"
            label="POC Mobile Number"
            value={editedOutlet.ownerMobileNumber || ""}
            error={!!errors.ownerMobileNumber}
            helperText={errors.ownerMobileNumber}
            style={{ marginTop: "10px", marginBottom: "10px" }}
          /> */}
          {/* <TextField
            label="POC Mobile Number"
            value={editedOutlet.ownerMobileNumber || ""}
            onChange={(e) =>
              handleFieldChange("ownerMobileNumber", e.target.value)
            }
            variant="outlined"
            error={!!errors.ownerMobileNumber}
            helperText={errors.ownerMobileNumber}
            style={{ margin: "5px" }}
          /> */}
          {/* <TextField
            disabled={isAdding ? false : true}
            label="POC Email"
            value={editedOutlet.ownerEmail || ""}
            onChange={(e) => handleFieldChange("ownerEmail", e.target.value)}
            variant="outlined"
            error={!!errors.ownerEmail}
            helperText={errors.ownerEmail}
            style={{ marginTop: "10px", marginBottom: "10px" }}
          /> */}
        </Stack>

        {/* <Alert severity="warning">
          if points of contact exists, then email and name won't update
        </Alert> */}

        <Stack>
          <Divider textAlign="center">ACTIONS</Divider>

          <FormLabel htmlFor="subscription-end-date">
            Subscription End Date
          </FormLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              //defaultValue = {editedOutlet.subscriptionEndDate}
              value={dayjs(
                new Date(editedOutlet.subscriptionEndDate).toLocaleDateString(
                  "en-US"
                )
              )}
              onChange={(date) =>
                handleFieldChange("subscriptionEndDate", date)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Subscription End Date"
                  fullWidth
                />
              )}
              format="DD/MM/YYYY"
              slotProps={{
                textField: {
                  error: !!errors.subscriptionEndDate,
                  helperText: errors.subscriptionEndDate,
                },
              }}
              //error={!!errors.subscriptionEndDate}
              //helperText={errors.subscriptionEndDate}
              disablePast
              style={{ margin: "10px" }}
            />
          </LocalizationProvider>
          <FormLabel id="is-active-label" style={{ margin: "10px" }}>
            Is Active
          </FormLabel>
          <Select
            labelId="is-active-label"
            id="is-active"
            value={editedOutlet.is_active}
            onChange={(e) => handleFieldChange("is_active", e.target.value)}
            input={<OutlinedInput />}
          >
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </Select>
          <FormLabel id="is-originType-label" style={{ margin: "10px" }}>
            Origin Type
          </FormLabel>

          <Select
            labelId="is-originType-label"
            id="is-outletType"
            value={editedOutlet.originType || "live"}
            onChange={(e) => handleFieldChange("originType", e.target.value)}
            input={<OutlinedInput />}
          >
            <MenuItem value={"petpooja demo"}>Petpooja Demo</MenuItem>
            <MenuItem value={"restaurant demo"}>Restaurant Demo</MenuItem>
            <MenuItem value={"live"}>Live</MenuItem>
            <MenuItem value={"id created"}>ID Created</MenuItem>
            <MenuItem value={"intenal"}>Internal</MenuItem>
            <MenuItem value={"external"}>external</MenuItem>
          </Select>
        </Stack>

        <Button onClick={handleOnClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          {isAdding ? "Add Outlet" : "Update"}
        </Button>
      </FormControl>
    </div>
  );
};

export default OutletDialog;
