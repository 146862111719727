import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete';
import WebAdminDialog from './webAdminDailog';
import webAdminApiService from '../services/webadmin.service';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { useNotification } from './Notification';


const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const WebAdmintable = ({ reload }) => {
  const classes = useStyles();
  const displayNotification = useNotification();

  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(-1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [webadmins, setWebadmins] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [editedWebAdmin, setEditedWebAdmin] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    password: '',
    isActive: true,
    roles: [],
    profileImage: null,
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setCurrentPage(-1);
    setWebadmins([]);
  };

  const handleOpenEditModal = (webadmin) => {
    setEditedWebAdmin(webadmin);
    setIsEditModalOpen(true);
  };

  const handleUpdateWebAdmin = async (id, editedAdmin) => {
    try {
      const result = await webAdminApiService.updateItem(id, editedAdmin);
      getWebAdmin(page, rowsPerPage, true);

      displayNotification('Webadmin Dashboard Updated Successfully', 'sucess');

      handleCloseEditModal();
    } catch (error) {
      displayNotification('Error occured while updating Webadmin', 'sucess');


      console.error("Error Occured while updating WebAdmin : ", error);
    }
  };

  const handleCloseEditModal = () => {
    setEditedWebAdmin({
      name: '',
      phoneNumber: '',
      email: '',
      password: '',
      isActive: true,
      roles: [],
      profileImage: null,
    });
    setIsEditModalOpen(false);
  };

  function removeLastNElements(arr, n) {
    if (n >= 0) {
      arr.splice(-n, n);
    } else {
      console.error("Invalid value of N. N should be a non-negative integer.");
    }
  }

  const onDelete = async (id) => {
    try {
      const result = await webAdminApiService.deleteWebAdmin(id);
      console.log("Deleted");

      displayNotification('Webadmin deleted Successfully', 'sucess');


      if (result === "Login") {
        navigate('/');
      } else {
        getWebAdmin(page, rowsPerPage, true);
      }
    } catch (error) {
      displayNotification('Error occured while deleting Webadmin', 'sucess');


      console.error("Error Occured when deleting WebAdmin : ", error);
    }
  }

  const getWebAdmin = async (page, rowsPerPage, isForceCall = false) => {
    // if(currentPage >= page && !isForceCall) {
    //   console.log("Skip API call");
    // } else {
    try {
      setLoading(true);
      const result = await webAdminApiService.getWebAdmin(page, rowsPerPage);
      var data = [];
      // if (isForceCall) {
      //   const tempData = [...webadmins];
      //   removeLastNElements(tempData, rowsPerPage);
      //   data = [...tempData, ...result.results.data.webAdmins];
      // } else {
      //   data = [...webadmins, ...result.results.data.webAdmins];
      // }
      setWebadmins(result.results.data.webAdmins);
      setTotalCount(result.results.data.totalUsers);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
      setCurrentPage(page);
    }
    // }
  };

  useEffect(() => {
    getWebAdmin(page, rowsPerPage);
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (reload) {
      getWebAdmin(page, rowsPerPage, true);
    }
  }, [reload]);

  useEffect(() => {
    getWebAdmin(page, rowsPerPage, true);
  }, [currentPage]);

  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : (
        <div>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="webadmin table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Mobile Number</TableCell>
                  <TableCell>Email Id</TableCell>
                  <TableCell>isActive</TableCell>
                  <TableCell>Profile Image</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {webadmins.map((webadmin) => (
                  // {webadmins.map((webadmin) => (
                  <TableRow key={webadmin._id}>
                    <TableCell>{webadmin.name}</TableCell>
                    <TableCell>{webadmin.phoneNumber}</TableCell>
                    <TableCell>{webadmin.email}</TableCell>
                    <TableCell>{webadmin.isActive ? "Yes" : "No"}</TableCell>
                    <TableCell>
                      {/* Assuming webadmin.logoImage is the URL of the image */}
                      <img src={webadmin.profileImage} alt="WebAdmin Profile Image" style={{ width: '50px', height: '50px' }} />
                    </TableCell>
                    <TableCell>
                      <div className={classes.actionsContainer}>
                        <IconButton color="primary" onClick={() => handleOpenEditModal(webadmin)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton color="secondary" onClick={() => onDelete(webadmin._id)}>
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <WebAdminDialog open={isEditModalOpen} onClose={handleCloseEditModal} onSave={handleUpdateWebAdmin} webadmin={editedWebAdmin} isAdding={false} />
        </div>
      )}
    </div>
  );
};

export default WebAdmintable;