import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Box from '@mui/material/Box';
import Dashboard from './pages/Dashboard';
import Users from './pages/Users';
import Outlets from './pages/Outlets';
import Appbar from './components/Appbar';
import WebAdmin from './pages/Adminusers';
import Login from './pages/Login';
import Layout from './components/Layout';
import NotFound from './pages/NotFound';
import OutletForm from './components/OutletForm'
import NotificationProvider from './components/Notification'; // Import the NotificationProvider
import CountrySelector from './components/CountrySelect';
import HoPage from './pages/HoPage';
import HoForm from './components/HoForm';
import HoEditForm from './components/HoEditForm';

function App() {
  return (
    <Router>
      <NotificationProvider> {/* Wrap your entire application with NotificationProvider */}
        <Box sx={{ flexGrow: 1 }}>
          {/* <Appbar title={title}/> */}
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route path="/dashboard" exact element={<Layout title='Dashboard' children={<Dashboard />} />} />
            <Route path="/hoPage" exact element={<Layout title='Hopage' children={<HoPage />} />} />
            <Route path="/hoform" exact element={<Layout title='Hoform' children={<HoForm />} />} />
            <Route path="/hoeditform/:id" exact element={<Layout title='Hoform' children={<HoEditForm />} />} />
            <Route path="/users" exact element={<Layout title='User Details' children={<Users />} />} />
            <Route path="/outlets" exact element={<Layout title='Outlet Details' children={<Outlets />} />} />
            <Route path="/outlets/add" exact element={<Layout title='Add Outlet' children={<OutletForm isAdding={true} />} />} />
            <Route path="/outlets/edit/:id" exact element={<Layout title='Update Outlet' children={<OutletForm isAdding={false} />} />} />
            <Route path="/webadmins" exact element={<Layout title='WebAdmin Details' children={<WebAdmin />} />} />
            <Route path="/country" exact element={<Layout title='WebAdmin Details' children={<CountrySelector />} />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Box>
      </NotificationProvider>
    </Router>
  );
}

export default App;
