import {
  Box,
  Button,
  Modal,
  Typography,
  Grid,
  TextField,
  Select,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Divider,
  Switch,
  FormControl,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  FormLabel,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useAddHeadOfficeMutation } from "../services/redux/hoApi";
import countriesData from "../components/countryJson.json";
import { industryTypes } from "../services/utilities";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import PhoneNumberInput from "material-ui-phone-number";
import { toast } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const HoForm = () => {
  const [openEdit, setOpenEdit] = useState(false);
  const handleEditOpen = () => setOpenEdit(true);
  const handleEditClose = () => setOpenEdit(false);

  const [selectedItem, setSelectedItem] = useState({});
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("India");
  const [selectedState, setSelectedState] = useState("Select State");
  const [selectedCity, setSelectedCity] = useState("Select City");
  const [hasPetpoojaId, setHasPetpoojaId] = useState("no");
  const [phone, setPhone] = useState("");
  const [pocPhone, setPocPhone] = useState("");
  const [hoData, setHoData] = useState({});
  const [preview, setPreview] = useState();
  const [addHeadOffice, { isLoading, isSuccess: headOfficeAdded }] =
    useAddHeadOfficeMutation();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      hoName: "",
      mobile: "",
      email: "",
      industryType: "restaurant",
      originType: "",
      pincode: "",
      address: "",
      country: "India",
      state: "",
      city: "",
      isActive: "yes",
      profile_photos_pet_pooja_task_manager: "",
      pocPhone: "",
      pocName: "",
      pocEmail: "",
      orgAccess: [],
      gstNumber: "",
      createOutlet: true,
      isPetPoojaUser: "no",
      petPoojaId: "",
      outletName: "",
      subscriptionEndDate: new Date(
        new Date().setMonth(new Date().getMonth() + 1)
      ),
    },
    validationSchema: yup.object({
      hoName: yup.string().required("Ho Name name is required"),
      industryType: yup.string().required("Industry type is required"),
      originType: yup.string().required("Origin type is required"),
      pincode: yup.string().required("Pincode is required"),
      address: yup.string().required("Address is required"),
      country: yup.string().required("Country is required"),
      state: yup.string().required("State is required"),
      city: yup.string().required("City is required"),
      isActive: yup.string().required("Is Active is required"),
      // profile_photos_pet_pooja_task_manager: yup.string().required("Logo is required"),
      isPetPoojaUser: yup.string().required("isPetPoojaUser is required"),
      pocPhone: yup.string().required("POC Phone is required"),
      pocName: yup.string().required("POC Name is required"),
      pocEmail: yup
        .string()
        .email("Please enter a valid email")
        .required("POC Email is required"),
      // createOutlet: yup.boolean().required("createOutlet is required"),
    }),
    onSubmit: (values, { resetForm }) => {
        console.log("submit", values)
      const fd = new FormData();
      for (const key in values) {
        fd.append(key, values[key]);
        // console.log( values[key]);
      }
      console.log(fd);
      addHeadOffice(fd);
      resetForm();
      setPreview("");
      formik.setFieldValue("pocPhone", "");
    },
  });

  const handleFileChange = (e) => {
    setPreview(URL.createObjectURL(e.target.files[0]));
    formik.setFieldValue(
      "profile_photos_pet_pooja_task_manager",
      e.target.files[0]
    );
  };

  const handlePhoneChange = (value) => {
    value = value.replace(/\s/g, "").replace(/\D/g, "");
    value = `+${value}`;
    setPhone(value);
    formik.setFieldValue("mobile", value);
  };
  const handlePOCPhoneChange = (value) => {
    value = value.replace(/\s/g, "").replace(/\D/g, "");
    value = `+${value}`;
    setPocPhone(value);
    formik.setFieldValue("pocPhone", value);
  };
  const handleCountryChange = (e) => {
    formik.setFieldValue("country", e.target.value);
    setSelectedState("");
    setSelectedCity("");
  };

  const handleStateChange = (e) => {
    formik.setFieldValue("state", e.target.value);
    setSelectedCity("");
  };

  const handleCityChange = (e) => {
    setSelectedCity("city", e.target.value);
    formik.setFieldValue("city", e.target.value);
  };

  const handleCreateOutletChange = (event) => {
    console.log("createOutlet", event);
    formik.setFieldValue("createOutlet", event);
  };

  const handlePetpoojaIDChange = (event) => {
    console.log("petpoojaID", event);
    formik.setFieldValue("petpoojaID", event);
  };

  const handleIsPetpoojaUserChange = (event) => {
    console.log("isPetPoojaUser", event);
    formik.setFieldValue("isPetPoojaUser", event);
  };

  const handleSubscriptionEndDateChange = (event) => {
    console.log("isPetPoojaUser", event);
    formik.setFieldValue("subscriptionEndDate", event);
  };

  const handleFieldChange = (field, value) => {
    console.log(field, value);
    if (field === "country") {
      handleCountryChange(value);
    } else if (field === "state") {
      handleStateChange(value);
    } else if (field === "city") {
      handleCityChange(value);
    } else if (field === "createOutlet") {
      handleCreateOutletChange(value);
    } else if (field === "hasPettpoojaID") {
      handleIsPetpoojaUserChange(value);
      value === "no" && handlePetpoojaIDChange("");
    } else if (field === "subscriptionEndDate") {
      handleSubscriptionEndDateChange(value);
    }
  };

  const handleClearLogoImage = (e) => {
    document.getElementById("logo").value = "";
    setPreview("");
  };

  useEffect(() => {
    if (headOfficeAdded) {
      toast.success("Head office added successfully");
      navigate("/hoPage");
    }
  }, [headOfficeAdded]);

  useEffect(() => {
    setCountryOptions(countriesData);
    try {
    } catch (error) {
      console.error("Error parsing countries data:", error);
    }
  }, []);
  return (
    <>
      <Box sx={{ p: 5 }}>
        <form onSubmit={formik.handleSubmit}>
          <Typography
            variant="h5"
            sx={{ marginBottom: 2, textAlign: "center" }}
          >
            Ho Registration Form
          </Typography>
          {/* <pre>{JSON.stringify(formik.values, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(formik.errors, null, 2)}</pre> */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                Ho Full name
              </Typography>
              <TextField
                fullWidth
                label="Full Name"
                name="hoName"
                variant="outlined"
                required
                error={formik.touched.hoName && Boolean(formik.errors.hoName)}
                {...formik.getFieldProps("hoName")}
              />
              {formik.touched.hoName && formik.errors.hoName && (
                <Typography variant="body2" color="error">
                  {formik.errors.hoName}
                </Typography>
              )}
            </Grid>

            {/* <Grid item xs={12} sm={6}>
                        <Typography variant="body1" sx={{ marginBottom: 1 }}>User role</Typography>
                        <Select fullWidth id="role-select" name="role" value={formik.values.role}
                            onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.role && Boolean(formik.errors.role)} variant="outlined" >
                            <MenuItem value="">Select Role</MenuItem>
                            <MenuItem value="Manager">Manager</MenuItem>
                            <MenuItem value="Employee">Employee</MenuItem>
                        </Select>
                        {formik.touched.role && formik.errors.role && (<Typography variant="body2" color="error">{formik.errors.role}</Typography>)}
                    </Grid> */}

            <Grid item xs={12} sm={3}>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                Industry Type
              </Typography>
              <Select
                fullWidth
                id="industryType"
                name="industryType"
                value={formik.values.industryType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.industryType &&
                  Boolean(formik.errors.industryType)
                }
                variant="outlined"
              >
                <MenuItem value="">Select industryType</MenuItem>
                {industryTypes.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.industryType && formik.errors.industryType && (
                <Typography variant="body2" color="error">
                  {formik.errors.industryType}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={3}>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                Origin Type
              </Typography>
              <Select
                fullWidth
                id="originType"
                name="originType"
                value={formik.values.originType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.originType && Boolean(formik.errors.originType)
                }
                variant="outlined"
              >
                <MenuItem value={"petpooja demo"}>Petpooja Demo</MenuItem>
                <MenuItem value={"restaurant demo"}>Restaurant Demo</MenuItem>
                <MenuItem value={"live"}>Live</MenuItem>
                <MenuItem value={"id created"}>ID Created</MenuItem>
                <MenuItem value={"intenal"}>Internal</MenuItem>
                <MenuItem value={"external"}>external</MenuItem>
              </Select>
              {formik.touched.originType && formik.errors.originType && (
                <Typography variant="body2" color="error">
                  {formik.errors.originType}
                </Typography>
              )}
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                Pin-code
              </Typography>
              <TextField
                fullWidth
                label="pincode"
                name="pincode"
                variant="outlined"
                error={formik.touched.pincode && Boolean(formik.errors.pincode)}
                {...formik.getFieldProps("pincode")}
              />
              {formik.touched.pincode && formik.errors.pincode && (
                <Typography variant="body2" color="error">
                  {formik.errors.pincode}
                </Typography>
              )}
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                Address
              </Typography>
              <TextField
                fullWidth
                label="Address"
                name="address"
                variant="outlined"
                error={formik.touched.address && Boolean(formik.errors.address)}
                {...formik.getFieldProps("address")}
              />
              {formik.touched.address && formik.errors.address && (
                <Typography variant="body2" color="error">
                  {formik.errors.address}
                </Typography>
              )}
            </Grid>

            <Grid item xs={4} sm={4}>
              <FormControl
                variant="outlined"
                sx={{ width: "100%", minWidth: 75 }}
              >
                <label htmlFor="country" style={{ color: "#808080" }}>
                  Select Country
                </label>
                <Select
                  id="country"
                  label="Select country"
                  value={formik.values.country || selectedCountry}
                  onChange={handleCountryChange}
                >
                  <MenuItem>Select Country</MenuItem>
                  {countryOptions.map((country) => (
                    <MenuItem key={country.id} value={country.name}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.country && formik.errors.country && (
                  <Typography variant="body2" color="error">
                    {formik.errors.country}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={4} sm={4}>
              <FormControl
                variant="outlined"
                sx={{ width: "100%", minWidth: 75 }}
              >
                <label htmlFor="state" style={{ color: "#808080" }}>
                  Select State
                </label>
                <Select
                  id="state"
                  label="Select State"
                  onChange={handleStateChange}
                >
                  <MenuItem>Select State</MenuItem>
                  {countryOptions
                    .find((country) => country.name === formik.values.country)
                    ?.state.map((x) => (
                      <MenuItem key={x.id} value={x.name}>
                        {x.name}
                      </MenuItem>
                    ))}
                </Select>
                {formik.touched.state && formik.errors.state && (
                  <Typography variant="body2" color="error">
                    {formik.errors.state}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={4} sm={4}>
              <FormControl
                variant="outlined"
                sx={{ width: "100%", minWidth: 75 }}
              >
                <label htmlFor="city" style={{ color: "#808080" }}>
                  Select City
                </label>
                <Select
                  id="city"
                  label="Select City"
                  onChange={handleCityChange}
                >
                  <MenuItem value="">Select City</MenuItem>
                  {countryOptions
                    .find((country) => country.name === formik.values.country)
                    ?.state.find((state) => state.name === formik.values.state)
                    ?.city.map((c) => (
                      <MenuItem key={c.id} value={c.name}>
                        {c.name}
                      </MenuItem>
                    ))}
                </Select>
                {formik.touched.city && formik.errors.city && (
                  <Typography variant="body2" color="error">
                    {formik.errors.city}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                Gst Number
              </Typography>
              <TextField
                fullWidth
                label="GST Number"
                name="gstNumber"
                variant="outlined"
                error={
                  formik.touched.gstNumber && Boolean(formik.errors.gstNumber)
                }
                {...formik.getFieldProps("gstNumber")}
              />
              {formik.touched.gstNumber && formik.errors.gstNumber && (
                <Typography variant="body2" color="error">
                  {formik.errors.gstNumber}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                Active
              </Typography>
              <Select
                fullWidth
                id="isActive-select"
                name="isActive"
                value={formik.values.isActive}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.isActive && Boolean(formik.errors.isActive)
                }
                variant="outlined"
              >
                <MenuItem>Select Active</MenuItem>
                <MenuItem default value="yes">
                  yes
                </MenuItem>
                <MenuItem value="no">no</MenuItem>
              </Select>
              {formik.touched.isActive && formik.errors.isActive && (
                <Typography variant="body2" color="error">
                  {formik.errors.isActive}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={3}>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                Logo
              </Typography>
              <TextField
                type="file"
                id="logo"
                fullWidth
                name="logo"
                variant="outlined"
                onChange={handleFileChange}
                error={formik.touched.logo && Boolean(formik.errors.logo)}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              {preview && (
                <div>
                  <img
                    src={preview}
                    alt="Logo"
                    style={{ width: "100px", marginTop: "10px" }}
                  />
                  <Button
                    onClick={handleClearLogoImage}
                    color="secondary"
                    variant="outlined"
                    style={{ marginRight: "10px" }}
                  >
                    {" "}
                    Clear Logo{" "}
                  </Button>
                </div>
              )}
            </Grid>

            <Grid item xs={6}>
              <FormControlLabel
                {...formik.getFieldProps("createOutlet")}
                control={
                  <Checkbox
                    checked={formik.values.createOutlet}
                    onChange={(e) => {
                      console.log(
                        "createOutlet 2",
                        !formik.values.createOutlet
                      );
                      handleFieldChange(
                        "createOutlet",
                        !formik.values.createOutlet
                      );
                    }}
                    color="primary"
                  />
                }
                label="Create an outlet"
              />
            </Grid>
            {formik.values.createOutlet && (
              <>
                <Grid item xs={12}>
                  <Divider textAlign="center">Outlet DETAILS</Divider>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="body1" sx={{ marginBottom: 1 }}>
                    Outlet Name
                  </Typography>
                  <TextField
                    fullWidth
                    label="Outlet Name"
                    name="outletName"
                    variant="outlined"
                    error={
                      formik.touched.outletName &&
                      Boolean(formik.errors.outletName)
                    }
                    {...formik.getFieldProps("outletName")}
                  />
                  {formik.touched.outletName && formik.errors.outletName && (
                    <Typography variant="body2" color="error">
                      {formik.errors.outletName}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="body1" sx={{ marginBottom: 1 }}>
                    Subscription End Date
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                    sx={{width: "100%"}}
                      //defaultValue = {editedOutlet.subscriptionEndDate}
                      value={dayjs(
                        new Date(
                          formik.values.subscriptionEndDate
                        ).toLocaleDateString("en-US")
                      )}
                      onChange={(date) =>
                        handleFieldChange("subscriptionEndDate", date)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Subscription End Date"
                        //   style={{ width: "100%" }}
                          fullWidth
                        />
                      )}
                      format="DD/MM/YYYY"
                      //   slotProps={{
                      //     textField: {
                      //       error: !!errors.subscriptionEndDate,
                      //       helperText: errors.subscriptionEndDate,
                      //     },
                      //   }}
                      //error={!!errors.subscriptionEndDate}
                      //helperText={errors.subscriptionEndDate}
                      
                      disablePast
                    //   style={{ margin: "10px" }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      marginLeft: "8px",
                    }}
                  >
                    <FormLabel style={{ color: "#808080" }}>
                      Already Petpooja User?
                    </FormLabel>
                    <ToggleButtonGroup
                      color="primary"
                      value={formik.values.isPetPoojaUser}
                      exclusive
                      onChange={(e) =>
                        handleFieldChange("hasPettpoojaID", e.target.value)
                      }
                      aria-label="Do you have a Petpooja ID?"
                    >
                      <ToggleButton
                        style={{ color: "black", height: "70%" }}
                        value="yes"
                      >
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        style={{ color: "black", height: "70%" }}
                        value="no"
                      >
                        No
                      </ToggleButton>
                    </ToggleButtonGroup>

                    {formik.values.isPetPoojaUser === "yes" && (
                      <TextField
                        label="Petpooja ID"
                        fullWidth
                        value={formik.values.petPoojaId}
                        name="petPoojaId"
                        variant="outlined"
                        error={
                          formik.touched.petPoojaId &&
                          Boolean(formik.errors.petPoojaId)
                        }
                        {...formik.getFieldProps("petPoojaId")}
                      />
                    )}
                  </Stack>
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Divider textAlign="center">HO POC DETAILS</Divider>
            </Grid>

            <Grid item xs={4}>
              <TextField
                label="POC Name"
                {...formik.getFieldProps("pocName")}
                variant="outlined"
                style={{
                  width: "100%",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              />
              {formik.touched.pocName && formik.errors.pocName && (
                <Typography variant="body2" color="error">
                  {formik.errors.pocName}
                </Typography>
              )}
            </Grid>

            <Grid item xs={4}>
              <PhoneNumberInput
                countryCodeEditable={false}
                label="POC Mobile Number"
                autoFormat={false}
                defaultCountry="in"
                onChange={handlePOCPhoneChange}
                variant="outlined"
                style={{
                  width: "100%",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              />
              {formik.touched.pocPhone && formik.errors.pocPhone && (
                <Typography variant="body2" color="error">
                  {formik.errors.pocPhone}
                </Typography>
              )}
            </Grid>

            <Grid item xs={4}>
              <TextField
                label="POC Email"
                {...formik.getFieldProps("pocEmail")}
                variant="outlined"
                style={{
                  width: "100%",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              />
              {formik.touched.pocEmail && formik.errors.pocEmail && (
                <Typography variant="body2" color="error">
                  {formik.errors.pocEmail}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ marginTop: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Link to="/hoPage">
                      <Button fullWidth variant="outlined">
                        Cancel
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                    >
                      Add Head Office
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default HoForm;
