import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import ClearIcon from '@mui/icons-material/Clear';
import MenuItem from '@mui/material/MenuItem';
import { isNotValidIndianMobileNumber, isNotValidPetpoojaEmail } from '../services/utilities';

const WebAdminDialog = ({ open, onClose, onSave, webadmin, isAdding }) => {
  const [editedWebAdmin, setEditedWebAdmin] = useState({ ...webadmin });
  const [editedAdmin, setEditedAdmin] = useState({});
  const [password, setPassword] = useState('');

  const [errors, setErrors] = useState({
    phoneNumber: '',
    email: '',
    name: '',
    password: ''
  });

  const validateForm = () => {
    let valid = true;
    const newErrors = { 
      phoneNumber: '',
      email: '',
      name: '',
      password: ''
    };

    if(editedWebAdmin.name.trim() === '')
    {
      newErrors.name = "Name is required";
      valid = false;
    }

    if(editedWebAdmin.phoneNumber.trim() === '')
    {
      newErrors.phoneNumber = "Mobile Number is required";
      valid = false;
    } 
    else if(isNotValidIndianMobileNumber(editedWebAdmin.phoneNumber)) 
    {
      newErrors.phoneNumber = "Mobile Number is invalid";
      valid = false;
    }

    if(editedWebAdmin.email.trim() === '')
    {
      newErrors.email = "Email is required";
      valid = false;
    }
    else if(isNotValidPetpoojaEmail(editedWebAdmin.email))
    {
      newErrors.email = "Email is Invalid";
      valid = false;
    }

    if(editedWebAdmin.password.trim() === '')
    {
      newErrors.password = "Password is required";
      valid = false;
    } 
    else if(editedWebAdmin.password.length < 8)
    {
      newErrors.password = "Password length should be greater than 8 characters";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  }

  useEffect(() => {
    if(open)
    {
      setEditedWebAdmin({ ...webadmin });
      setPassword('');
    }
  }, [webadmin, open]);

  const handleFieldChange = (field, value) => {
    setEditedWebAdmin((prev) => ({ ...prev, [field]: value }));
    setEditedAdmin((prev) => ({ ...prev, [field]: value }));

    // Clear the error message when the user starts typing
    setErrors({
      ...errors,
      [field]: '',
    });
  };

  const handleOnClose = () => {
    setErrors({
      phoneNumber: '',
      email: '',
      name: '',
      password: ''
    });
    setEditedAdmin({
      name: '',
      phoneNumber: '',
      email: '',
      password: '',
      isActive: true,
      roles: [], 
      profileImage: null,
    });
    setEditedWebAdmin({});
    onClose();
  }

  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
  
    if (file) {
      // Validate the file extension
      const allowedExtensions = ['jpg', 'jpeg', 'png'];
      const fileExtension = file.name.split('.').pop().toLowerCase();
  
      if (allowedExtensions.includes(fileExtension)) {
        setEditedAdmin((prev) => ({ ...prev, "profile_photos_pet_pooja_task_manager": file }));
        setEditedWebAdmin((prev) => ({ ...prev, "profile_photos_pet_pooja_task_manager": file }));
  
        // Generate a preview for the selected image
        const reader = new FileReader();
        reader.onloadend = () => {
            handleFieldChange("profileImage", reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert('Invalid file type. Please upload a valid image file.');
        e.target.value = ''; 
        //setEditedWebAdmin((prev) => ({ ...prev, profileImage: null }));
        //setProfilePreview('');
      }
    } else {
      // Reset the profileImage and preview if no file selected
      handleFieldChange("profileImage", null);
      //setEditedWebAdmin((prev) => ({ ...prev, profileImage: null }));
      //setProfilePreview('');
    }
  };

  const handleClearProfileImage = () => {
    handleFieldChange("profileImage", null);
    //setEditedWebAdmin((prev) => ({ ...prev, profileImage: null }));
    //setProfilePreview('');
  };

  const handleSave = () => {
    if (validateForm()) {
      if(isAdding) {
        onSave(editedWebAdmin);
      } else {
        if(Object.keys(editedAdmin).length > 0) {
          onSave(editedWebAdmin._id ,editedAdmin);
        }
      }
      handleOnClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleOnClose} aria-labelledby="webadmin-dialog-title" maxWidth="md" style={{ width: '80%', margin: 'auto' }}>
      <DialogTitle id="webadmin-dialog-title">
        {isAdding ? 'Add New WebAdmin' : 'Edit WebAdmin Details'}
        <IconButton style={{ position: 'absolute', right: '8px', top: '8px' }} onClick={handleOnClose}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          value={editedWebAdmin.name}
          onChange={(e) => handleFieldChange('name', e.target.value)}
          variant="outlined"
          error={!!errors.name}
          helperText={errors.name}
          style={{margin : '5px'}}
        />
        <TextField
            label="Mobile Number"
            value={editedWebAdmin.phoneNumber || ''}
            onChange={(e) => handleFieldChange('phoneNumber', e.target.value)}
            variant="outlined"
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber}
            style={{margin : '5px'}}
          />
          <TextField
            label="Email"
            value={editedWebAdmin.email || ''}
            onChange={(e) => handleFieldChange('email', e.target.value)}
            variant="outlined"
            error={!!errors.email}
            helperText={errors.email}
            style={{margin : '5px'}}
          />
          <TextField
            label="Password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              handleFieldChange('password', e.target.value)
            }}
            variant="outlined"
            error={!!errors.password}
            helperText={errors.password}
            style={{margin : '5px'}}
          />
          <InputLabel id="is-active-label">Is Active</InputLabel>
          <Select
            labelId="is-active-label"
            id="is-active"
            value={editedWebAdmin.isActive}
            onChange={(e) => handleFieldChange('isActive', e.target.value)}
            input={<OutlinedInput />}
          >
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </Select>
          <InputLabel htmlFor="profile">Profile Image</InputLabel> 
        <TextField
          type="file"
          onChange={handleProfileImageChange}
          variant="outlined"
          style={{margin : '5px'}}
        />
        {/* Profile preview and clear button */}
        {editedWebAdmin.profileImage && (
          <div>
            <img
              src={editedWebAdmin.profileImage}
              alt="Profile Preview"
              style={{ maxWidth: '100%', marginTop: '10px' }}
            />
            <Button
              onClick={handleClearProfileImage}
              color="secondary"
              variant="outlined"
              style={{ marginTop: '10px' }}
            >
              Clear Profile
            </Button>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          {isAdding ? 'Add WebAdmin' : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WebAdminDialog;
