import {
  Box,
  Button,
  Modal,
  Typography,
  Grid,
  Divider,
  TextField,
  Select,
  MenuItem,
  Switch,
  FormControl,
  IconButton,
  Table,
  Stack,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useState, useEffect } from "react";
import {
  useAddHeadOfficeMutation,
  useDeleteHeadOfficeMutation,
  useGetAllHeadOfficeQuery,
  useUpdateHeadOfficeMutation,
} from "../services/redux/hoApi";
import countriesData from "../components/countryJson.json";
import { industryTypes } from "../services/utilities";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import HoTable from "../components/HoTable";

const HoPage = () => {
  const navigate = useNavigate();
  // const { data, isLoading, isError, error } = useGetAllHeadOfficeQuery();
  const [updateHeadOffice] = useUpdateHeadOfficeMutation();
  const [deleteHeadOffice] = useDeleteHeadOfficeMutation();
  // console.log(data);
  const [key, setKey] = useState(false);

  return (
    <>
      {/* <pre>{JSON.stringify(selectedItem,null,2)}</pre> */}
      <Box>
        <Box>
          <Box sx={{ textAlign: "end", width: "100%" }}>
            <Link to="/hoform">
              <Button variant="contained" color="primary" sx={{ margin: 2 }}>
                Add head office
              </Button>
            </Link>
          </Box>
        </Box>
        <HoTable reload={key} />
        {/* <Box sx={{ padding: 2 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>HO Logo</TableCell>
                  <TableCell>HO Name</TableCell>
                  <TableCell>HO Phone Number</TableCell>
                  <TableCell>HO Email</TableCell>
                  <TableCell>Industry Type</TableCell>
                  <TableCell>Origin Type</TableCell>
                  <TableCell>Pin Code</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>country</TableCell>
                  <TableCell>state</TableCell>
                  <TableCell>city</TableCell>
                  <TableCell>Poc Name</TableCell>
                  <TableCell>Poc Phone number</TableCell>
                  <TableCell>Poc Email</TableCell>
                  <TableCell>Employee List</TableCell>
                  <TableCell>Outlet Access</TableCell>
                  <TableCell>Sub Ho</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody> */}
        {/* {data &&
                  data.hoData
                  .map((item)=> {
                    console.log(item);
                     return (
                        <TableRow
                          key={item.userId}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell>
                            <div style={{ marginTop: 10 }}>
                              <img
                                src={item.logo}
                                alt="img"
                                style={{
                                  width: 30,
                                  height: 30,
                                  borderRadius: "50%",
                                }}
                              />
                            </div>
                          </TableCell>
                          <TableCell>{item.hoName}</TableCell>
                          <TableCell>{item.mobile}</TableCell>
                          <TableCell>{item.email}</TableCell>
                          <TableCell>{item.industryType}</TableCell>
                          <TableCell>{item.originType}</TableCell>
                          <TableCell>{item.pincode}</TableCell>
                          <TableCell>{item.address}</TableCell>
                          <TableCell>{item.country}</TableCell>
                          <TableCell>{item.state}</TableCell>
                          <TableCell>{item.city}</TableCell>
                          <TableCell>{item.ownerName}</TableCell>
                          <TableCell>{item.ownerMobileNumber}</TableCell>
                          <TableCell>{item.ownerEmail}</TableCell>
                          <TableCell>{item.employeeList!=undefined? item.employeeList.length:0}</TableCell>
                          <TableCell>{item.orgAccess != undefined? item.orgAccess.length:0}</TableCell>
                          <TableCell>{item.subHo != undefined?item.subHo.length:0}</TableCell>
                          <TableCell>{item.is_active ? "Yes" : "No"}</TableCell>
                          <TableCell>
                            <IconButton color="primary">
                              <Link to={`/hoeditform/${item._id}`}>
                                <EditIcon />
                              </Link>
                            </IconButton>
                            <IconButton
                              color="secondary"
                              onClick={() => deleteHeadOffice(item)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )

                  } 
                  )} */}
        {/* </TableBody>
            </Table>
          </TableContainer>
        </Box> */}
      </Box>
    </>
  );
};

export default HoPage;
