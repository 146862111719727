import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Appbar from "./Appbar"

const Layout = ({ children , title}) => {
    const navigate = useNavigate();

    useEffect(() => {
        const isAuthenticated = !!localStorage.getItem('accessToken');
        if(!isAuthenticated)
        {
            navigate('/');
        }
      }, [navigate]);  

    return (
        <div>
            <Appbar title={title}/>
            {children}
        </div>
    );
}

export default Layout;