import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const BASE_URL = process.env.REACT_APP_API_URL;

export const hoApi = createApi({
  reducerPath: "hoApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ["hoApi"],
  endpoints: (builder) => {
    return {
      getAllHeadOffice: builder.query({
        query: ({ page, rowsPerPage, fieldKey, filterValue }) => {
          const accessToken = localStorage.getItem("accessToken");
          console.log(accessToken);
          return {
            url: "/api/head_office",
            method: "GET",
            params: {
              page,
              rowsPerPage,
              fieldKey,
              filterValue,
            },
            headers: {
              "x-access-token": accessToken,
            },
          };
        },
        transformResponse: (data) => data.results.data,
        providesTags: ["hoApi"],
      }),
      getHeadOfficeDetailsById: builder.query({
        query: (ho) => {
          const accessToken = localStorage.getItem("accessToken");
          return {
            // /api/head_office/employees/:id
            url: `/api/head_office/employees/${ho}`,
            method: "GET",
            headers: {
              "x-access-token": accessToken,
            },
          };
        },
        transformResponse: (data) => data.results.data,
        providesTags: ["employeetagName"],
      }),

      addHeadOffice: builder.mutation({
        query: (officeData) => {
          const accessToken = localStorage.getItem("accessToken");
          const ownerEmail = localStorage.getItem("email");
          officeData.append("createdByEmail", ownerEmail);
          console.log(officeData);
          return {
            url: "/api/head_office",
            method: "POST",
            body: officeData,
            headers: {
              "x-access-token": accessToken,
            },
          };
        },
        invalidatesTags: ["hoApi"],
      }),
      updateHeadOffice: builder.mutation({
        query: (officeData) => {
          const accessToken = localStorage.getItem("accessToken");
          const ownerEmail = localStorage.getItem("email");
          officeData.fd.append("createdByEmail", ownerEmail);
          officeData.fd.append("_id", officeData._id);
          return {
            url: `/api/head_office/${officeData._id}`,
            method: "PUT",
            body: officeData.fd,
            headers: {
              "x-access-token": accessToken,
            },
          };
        },
        transformResponse: (data) => data.result,
        invalidatesTags: ["hoApi"],
      }),
      deleteHeadOffice: builder.mutation({
        query: (officeData) => {
          const accessToken = localStorage.getItem("accessToken");
          return {
            url: `/api/head_office/${officeData._id}`,
            method: "DELETE",
            headers: {
              "x-access-token": accessToken,
            },
            // body: officeData
          };
        },
        invalidatesTags: ["hoApi"],
      }),
    };
  },
});

export const {
  useGetAllHeadOfficeQuery,
  // useLazyGetAllHeadOfficeQuery,
  useGetHeadOfficeDetailsByIdQuery,
  useAddHeadOfficeMutation,
  useDeleteHeadOfficeMutation,
  useUpdateHeadOfficeMutation,
} = hoApi;
