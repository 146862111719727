import React, { useState,useEffect } from 'react';
import Appbar from '../components/Appbar';
import { Box, IconButton, Typography, Card, Button } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility';
import GitHubIcon from '@mui/icons-material/GitHub';
import webAdminApiService from '../services/webadmin.service';

const Dashboard = () => {
  const [loginCount, setLoginCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const getDashboardData = async () => {
      setLoading(true);
      try {
        const result = await webAdminApiService.getWebAdminDashboardData();
        console.log(result.results.data);
        if(result.results.data.loginCount!=null){
          if(result.results.data.loginCount.length>0){
            if(result.results.data.loginCount[0].userLogin!=null){
              setLoginCount(result.results.data.loginCount[0].userLogin);
            }
          }
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <div>
      <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center", margin: 2 }}>
        <Card sx={{ maxWidth: 375, padding: 2 }}>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant='h6' > Today's login Count of user</Typography>
            <Typography variant="h6">{loginCount}</Typography>
          </Box>
        </Card>
      </Box>
    </div>
  );
};

export default Dashboard;
