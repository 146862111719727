import React, { useState, useEffect  } from 'react';
import Usertable from '../components/userTable';
import Button from '@mui/material/Button';
import UserDialog from '../components/userDailog';
import userApiService from '../services/user.apiservice';
import { useNotification } from '../components/Notification';
import { ToastContainer,toast } from 'react-toastify';

const dummyUser = [
    {
        id: 1,
        userName: 'Yashoeep',
        userMobileNumber: '8275861835',
        userEmail: 'yashodeep.kacholiya@petpooja.com',
        userCountryCode: '+91',
        userOrganization: [
          "Test", "Petpooja", "Dev"
        ],
        isActive: true,
        profileImage: 'https://www.kasandbox.org/programming-images/avatars/old-spice-man-blue.png',
    },
];



const User = () => {
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [key,setKey]=useState(false);
    const displayNotification = useNotification();

  
    const [newUser, setNewUser] = useState({
        name: '',
        phone: '',
        email: '',
        countryCode: '+91',
        userOrganization: [],
        is_active: true,
        profile: null,
        originType:'external'
    });
    
 
    const reloadUserTable=()=>{
      setKey(true);
    }


    const handleReloadPage = () => {
      window.location.reload();

    };
    
    const handleOpenAddModal = () => {
      //setNewAdminUser(outlet);
      setIsAddModalOpen(true);
    };
  
    const handleAddUser = async (user) => {
      try {
        const result = await userApiService.createUser(user);
        handleCloseAddModal();
        
        reloadUserTable();
        // handleReloadPage();
        displayNotification('User created successfully', 'success');

      } catch (error) {
        toast.error(`${error}`);

        console.error("Error Occured while create web Admin : ", error);
      }
      handleCloseAddModal();
    };
  
    const handleCloseAddModal = () => {
      setNewUser({
          name: '',
          phone: '',
          email: '',
          countryCode: '+91',
          userOrganization: [],
          is_active: true,
          profile: null,
          originType:'external'
        });
        setIsAddModalOpen(false);
    };
    


  
    return (
       <div>
          {/* <Appbar title="User"/> */}
          <div style={{margin: '5px'}}>  
          <div style={{textAlign: 'end'}}>
            <Button
                color="primary"
                variant="contained"
                style={{ marginBottom: '10px' }}
                onClick={handleOpenAddModal}
              >
                ADD USER
            </Button>
          </div>
          <Usertable reload={key}  />
          
          </div>
          <UserDialog open={isAddModalOpen} onClose={handleCloseAddModal} onSave={handleAddUser} user={newUser} isAdding={true}/>
          <ToastContainer position="top-center" autoClose={1000}
 />

      </div>
    );
  };
  
  export default User;