import { Box, Button, Modal, Typography, Grid,Divider, TextField, Select, MenuItem, Switch, FormControl, IconButton, Table, Stack } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect } from 'react'
import { useAddHeadOfficeMutation, useDeleteHeadOfficeMutation, useGetAllHeadOfficeQuery, useUpdateHeadOfficeMutation,useGetHeadOfficeDetailsByIdQuery } from '../services/redux/hoApi';
import countriesData from "../components/countryJson.json"
import { industryTypes, } from "../services/utilities";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useFormik } from 'formik';
import PhoneNumberInput from "material-ui-phone-number";
import * as yup from "yup"
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate,useParams } from "react-router-dom";

const HoEditForm = ({}) => {

    const { id } = useParams()
    
    const {data,isError,isSuccess}= useGetHeadOfficeDetailsByIdQuery(id)
    
    console.log(data&&data.is_active); 

    
    const [newImage,setNewImage] = useState(false)
    const navigate = useNavigate()
    const [phone, setPhone] = useState("")
    const [item, setEditItem] = useState({})
    const [updateHeadOffice,{ isLoading, isSuccess: headOfficeUpdated }] = useUpdateHeadOfficeMutation()
    const [countryOptions, setCountryOptions] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState("India");
    const [selectedState, setSelectedState] = useState("Select State");
    const [selectedCity, setSelectedCity] = useState("Select City");
    const [hasPetpoojaId, setHasPetpoojaId] = useState("no");
    const [pocPhone, setPocPhone] = useState("")
    const [preview, setPreview] = useState()
    
    const handlePhoneChange = (value) => {
        value = value.replace(/\s/g, "").replace(/\D/g, '');
        value = `+${value}`;
        setPhone(value)
        formik.setFieldValue("mobile", value)
    }

    const handleCountryChange = (e) => {
        formik.setFieldValue("country", e.target.value );
        setSelectedState();
        setSelectedCity("");
    };

    const handleStateChange = (e) => {
        formik.setFieldValue("state", e.target.value);
        setSelectedCity("");
    };

    const handleCityChange = (e) => {
        setSelectedCity("city", e.target.value);
        formik.setFieldValue("city", e.target.value);
    };

    const handleFieldChange = (field, value) => {
        if (field === "country") {
            handleCountryChange(value);
        } else if (field === "state") {
            handleStateChange(value);
        } else if (field === "city") {
            handleCityChange(value);
        }
    };

    const handlePOCPhoneChange = (value) => {
        value = value.replace(/\s/g, "").replace(/\D/g, '');
        value = `+${value}`;
        setPocPhone(value)
        formik.setFieldValue("pocPhone", value)
    }

    const handleClearLogoImage = (e) => {
        document.getElementById("logo").value = "";
        setPreview("")
    };

    const handleFileChange = (e) => {
        setPreview(URL.createObjectURL(e.target.files[0]))
        formik.setFieldValue("profile_photos_pet_pooja_task_manager", e.target.files[0]);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            hoName: data ? data.hoName: "",
            mobile: data ? data.mobile: "",
            email: data ? data.email:"",
            industryType: data ? data.industryType:"",
            originType: data ? data.originType:"",
            pincode: data ? data.pincode:"",
            address:data ? data.address: "",
            country: data ? data.country:"",
            state: data ? data.state:"",
            city:data ? data.city: "",
            is_active:data ? data.is_active: "",
            profile_photos_pet_pooja_task_manager:data ? data.profile_photos_pet_pooja_task_manager: "",
            isPetPoojaUser:data ? data.isPetPoojaUser: "",
            petPoojaId: data ? data.petPoojaId:"",
            pocPhone:data ? data.pocPhone: "",
            pocName: data ? data.pocName:"",
            pocEmail:data ? data.pocEmail: "",
            // orgAccess: [],
        },
        validationSchema: yup.object({

                }),
        onSubmit: (values, { resetForm }) => {
            console.log(values);
            const fd = new FormData()
            for (const key in values) {
                fd.append(key, values[key])
                // console.log( values[key]);
            }
            updateHeadOffice({fd,_id:id})
            resetForm()
        }
    })

    const handleEditChange = e => {
        const { value, name, type, files } = e.target
        type === "file"
            ? setEditItem({ ...item, [name]: files[0],})
            : setEditItem({ ...item, [name]: value })
    }

    useEffect(() => {
        setCountryOptions(countriesData);
        try {
        } catch (error) {
            console.error("Error parsing countries data:", error);
        }
    }, []);

    useEffect(() => {
        if (headOfficeUpdated) {
            toast.success("Head office added successfully")
        navigate("/hoPage")
        }
    }, [headOfficeUpdated]);

  return <>
  
                        <Box sx={{  padding: 5, }}>
                            <form onSubmit={formik.handleSubmit}>
                                <pre>{JSON.stringify(formik.values.isActive, null, 2)}</pre>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant='body1' sx={{ marginBottom: 1 }}>Full name</Typography>
                                        <TextField fullWidth label="Full Name" name="fullName" variant="outlined" {...formik.getFieldProps("hoName")} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1' sx={{ marginBottom: 1 }}>Ho Phone Number</Typography>
                        <PhoneNumberInput countryCodeEditable={false} value={data && data.mobile} autoFormat={false} defaultCountry="in" onChange={handlePhoneChange} variant="outlined" style={{ width: "100%" }} />
                                    </Grid>
                                                       
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1' sx={{ marginBottom: 1 }}>Email-ID</Typography>
                        <TextField fullWidth label="Email" name="email" type="email" variant="outlined" {...formik.getFieldProps('email')} />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography variant='body1' sx={{ marginBottom: 1 }}>industryType</Typography>
                        <Select fullWidth id="industryType" name="industryType" value={formik.values.industryType} onChange={formik.handleChange} onBlur={formik.handleBlur} variant="outlined">
                            <MenuItem value="">Select industryType</MenuItem>
                            {industryTypes.map((item) => (
                                <MenuItem key={item} value={item}>{item}</MenuItem>
                            ))}
                        </Select>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Typography variant='body1' sx={{ marginBottom: 1 }}>originType</Typography>
                        <Select fullWidth id="originType" name="originType" value={formik.values.originType} onChange={formik.handleChange} onBlur={formik.handleBlur}  variant="outlined">
                            <MenuItem value={"petpooja demo"}>Petpooja Demo</MenuItem>
                            <MenuItem value={"restaurant demo"}>Restaurant Demo</MenuItem>
                            <MenuItem value={"live"}>Live</MenuItem>
                            <MenuItem value={"id created"}>ID Created</MenuItem>
                            <MenuItem value={"intenal"}>Internal</MenuItem>
                            <MenuItem value={"external"}>external</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={3} >
                        <Typography variant='body1' sx={{ marginBottom: 1 }}>pincode</Typography>
                        <TextField fullWidth label="pincode" name="pincode" variant="outlined" {...formik.getFieldProps('pincode')} />
                       
                    </Grid>
                    <Grid item xs={9} >
                        <Typography variant='body1' sx={{ marginBottom: 1 }}>Address</Typography>
                        <TextField fullWidth label="Address" name="address" variant="outlined" {...formik.getFieldProps('address')} />
                    </Grid>

                    <Grid item xs={4} sm={4}>
                        <FormControl variant="outlined" sx={{ width: '100%', minWidth: 75 }}>
                            <label htmlFor="country" style={{ color: "#808080" }}>Select Country</label>
                            {/* {JSON.stringify(countryOptions,null,2)} */}
                            {/* {JSON.stringify(item.country,null,2)} */}
                            <Select id="country" label="Select country" value={formik.values.country} onChange={handleCountryChange}>
                                <MenuItem >Select Country</MenuItem>
                                {countryOptions.map((country) => (
                                    <MenuItem key={country.id} value={country.name}>{country.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <FormControl variant="outlined" sx={{ width: '100%', minWidth: 75 }}>
                            <label htmlFor="state" style={{ color: "#808080" }}>Select State</label>
                            <Select id="state" label="Select State" value={formik.values.state } onChange={handleStateChange} >
                                <MenuItem >Select State</MenuItem>
                                {countryOptions.find((country) => country.name === formik.values.country)?.state.map((x) => (
                                    <MenuItem key={x.id} value={x.name}>{x.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <FormControl variant="outlined" sx={{ width: '100%', minWidth: 75 }}>
                            <label htmlFor="city" style={{ color: "#808080" }}>Select City</label>
                            <Select id="city" label="Select City" value={formik.values.city} onChange={handleCityChange} >
                                <MenuItem value="">Select City</MenuItem>
                                {countryOptions
                                    .find((country) => country.name === formik.values.country)
                                    ?.state.find((state) => state.name === formik.values.state)
                                    ?.city.map((c) => (
                                        <MenuItem key={c.id} value={c.name}>{c.name}</MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Typography variant='body1' sx={{ marginBottom: 1 }}>Active</Typography>
                        <Select fullWidth id="isActive-select" name="isActive" value={formik.values.is_active} onChange={formik.handleChange} variant="outlined" >
                            <MenuItem >Select Active</MenuItem>
                            <MenuItem value="yes">yes</MenuItem>
                            <MenuItem value="no">no</MenuItem>
                        </Select>
                    </Grid>

                    {
                        newImage 
                        ?<Grid item xs={12} sm={3}>
                        <Typography variant='body1' sx={{ marginBottom: 1 }}>Logo</Typography>
                        <TextField type="file" id="logo" fullWidth name="logo" variant="outlined" onChange={handleFileChange} />
                        <Button onClick={e=> setNewImage(false)} color="secondary" variant="outlined" style={{ marginRight: "10px" }}> Cancel </Button>

                    </Grid>
                        :    <Grid item xs={12} sm={3}>
                        {data && data.logo &&  (
                            <div>
                                <img src={data && data.logo} alt="Logo" style={{ maxWidth: "30%"}} />
                                <Button onClick={e=> setNewImage(true)} color="secondary" variant="outlined" style={{ marginRight: "10px" }}> Add New Logo </Button>
                            </div>
                        )}
                    </Grid>
                    }


{
  data &&  !data.logo && !newImage &&<Grid item xs={12} sm={3}>
    <Typography variant='body1' sx={{ marginBottom: 1 }}>Logo</Typography>
    <TextField type="file" id="logo" fullWidth name="logo" variant="outlined" onChange={handleFileChange} />
    <Button onClick={e=> setNewImage(false)} color="secondary" variant="outlined" style={{ marginRight: "10px" }}> Cancel </Button>

</Grid>
}



                    {
                     preview&& <Grid item xs={12} sm={3}>
                    { preview&&  (
                        <div>
                            <img src={ preview} alt="Logo" style={{ maxWidth: "30%"}} />
                            <Button onClick={handleClearLogoImage} color="secondary" variant="outlined" style={{ marginRight: "10px" }}> Clear Logo </Button>
                        </div>
                    )}
                </Grid>
                   }                
                   

                    <Grid item xs={12} > <Divider textAlign="center">HO POC DETAILS</Divider></Grid>

                    <Grid item xs={4} >
                        <TextField label="POC Name" {...formik.getFieldProps('pocName')} variant="outlined" style={{ width: "100%" }} />
                    </Grid>

                    <Grid item xs={4} >
                        <PhoneNumberInput countryCodeEditable={false} label="POC Mobile Number" value={data && data.pocPhone} autoFormat={false} defaultCountry="in" onChange={handlePOCPhoneChange} variant="outlined" style={{ width: "100%" }} />
                    </Grid>

                    <Grid item xs={4} >
                        <TextField label="POC Email" {...formik.getFieldProps('pocEmail')} value={data && data.pocEmail} variant="outlined" style={{ width: "100%" }} />
                    </Grid>
                                <Grid item xs={6} >
                                    <Button type="submit" fullWidth variant="contained" color="primary" > Update Head Office </Button>
                                </Grid>
                                <Grid item xs={6} >
                                    <Link to="/hoPage" >
                                        <Button onClick={e=>{
                                            // setOpenEdit(false);
                                            // setSelectedItem({});
                                        }} fullWidth variant="contained"> Cancel </Button>
                                    </Link>
                                </Grid>
                </Grid>
                            </form>
                        </Box>
                   
                {/* )} */}
  
  
  </>
}

export default HoEditForm