import React, { useState, useEffect, createContext, useContext } from 'react';
import '../styles/Notification.css'; 

const NotificationContext = createContext();

export const useNotification = () => {
  return useContext(NotificationContext);
};

const NotificationProvider = ({ children }) => {
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('');

  useEffect(() => {
    if (showToast) {
      // Automatically hide the notification after 5 seconds
      const timer = setTimeout(() => {
        setShowToast(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showToast]);

  const displayNotification = (msg, notificationType) => {
    setMessage(msg);
    setType(notificationType);
    setShowToast(true);
  };

  return (
    <NotificationContext.Provider value={displayNotification}>
      {children}
      {showToast && (
        <div className={`notification ${type}`}>
          <p>{message}</p>
        </div>
      )}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
