import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Typography } from '@mui/material';

const NotFound = () => {
  return (
    <Container>
      <Typography variant="h1" align="center" color="textSecondary" gutterBottom>
        404 - Not Found
      </Typography>
      <Typography variant="h5" align="center" color="textSecondary" paragraph>
        The page you are looking for might be under construction or does not exist.
      </Typography>
      <Typography align="center">
        <Link to="/dashboard">
          <Button variant="contained" color="primary">
            Go to Dashboard
          </Button>
        </Link>
      </Typography>
    </Container>
  );
};

export default NotFound;