import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import ClearIcon from '@mui/icons-material/Clear';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { isNotValidIndianMobileNumber, industryTypes, isNotValidEmail, isNotFutureDate } from '../services/utilities';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OutletDialog = ({ open, onClose, onSave, outlet, isAdding }) => {
  const [editedOutlet, setEditedOutlet] = useState({ ...outlet });
  const [diffEditedOutlet, setDiffEditedOutlet] = useState({});

  const [errors, setErrors] = useState({
    name: '',
    city: '',
    state: '',
    zip_code: '',
    email:'',
    phone:'',
    ownerMobileNumber: '',
    ownerEmail: '',
    subscriptionEndDate: ''
  });

  const validateForm = () => {
    let valid = true;
    const newErrors = { 
      name: '',
      city: '',
      state: '',
      zip_code: '',
      email:'',
      phone:'',
      ownerMobileNumber: '',
      ownerEmail: '',
      subscriptionEndDate: ''
    };

    if(editedOutlet.name.trim() === '')
    {
      newErrors.name = "Name is required";
      valid = false;
    }

    if(editedOutlet.city.trim() === '')
    {
      newErrors.city = "City is required";
      valid = false;
    }

    if(editedOutlet.state.trim() === '')
    {
      newErrors.state = "State is required";
      valid = false;
    }

    if(editedOutlet.zip_code.trim() === '')
    {
      newErrors.zip_code = "Pincode is required";
      valid = false;
    }

    if(editedOutlet.ownerMobileNumber.trim() === '')
    {
      newErrors.ownerMobileNumber = "Mobile Number is required";
      valid = false;
    } 
    else if(isNotValidIndianMobileNumber(editedOutlet.ownerMobileNumber)) 
    {
      newErrors.ownerMobileNumber = "Mobile Number is invalid";
      valid = false;
    }

    if(editedOutlet.ownerEmail !== undefined && editedOutlet.ownerEmail !== null)
    {
      if(editedOutlet.ownerEmail.length > 0 && isNotValidEmail(editedOutlet.ownerEmail) && isAdding)
      {
        newErrors.ownerEmail = 'Email is Invalid';
        valid = false;
      }
    }

    if(editedOutlet.email === undefined)
    {
      newErrors.email = "Outlet Email is required";
      valid = false;
    }
    else if(editedOutlet.email.trim() === '')
    {
      newErrors.email = "Outlet Email is required";
      valid = false;
    }
    else if(isNotValidEmail(editedOutlet.email))
    {
      newErrors.email = 'Outlet Email is Invalid';
      valid = false;
    }

    if(editedOutlet.phone === undefined)
    {
      newErrors.phone = "Outlet Phone Number is required";
      valid = false;
    }
    else if(editedOutlet.phone.trim() === '')
    {
      newErrors.phone = "Outlet Phone Number is required";
      valid = false;
    } 
    else if(isNotValidIndianMobileNumber(editedOutlet.phone)) 
    {
      newErrors.phone = "Outlet Phone Number is invalid";
      valid = false;
    }

    if(editedOutlet.subscriptionEndDate === '')
    {
      newErrors.subscriptionEndDate = "Subscription End Date is required";
      valid = false;
    } else if (isNotFutureDate(editedOutlet.subscriptionEndDate) && editedOutlet.is_active){
      console.log("Invalid Subscription date");
      newErrors.subscriptionEndDate = "Subscription End Date is Invalid";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  }

  useEffect(() => {
    if(open) {
      setEditedOutlet({ ...outlet });
    }
  }, [outlet, open]);

  const handleFieldChange = (field, value) => {

    setEditedOutlet((prev) => ({ ...prev, [field]: value }));
    setDiffEditedOutlet((prev) => ({ ...prev, [field]: value }));

    // Clear the error message when the user starts typing
    setErrors({
      ...errors,
      [field]: '',
    });
  };

  const handleOnClose = () => {
    setErrors({
      name: '',
      city: '',
      state: '',
      zip_code: '',
      email:'',
      phone:'',
      ownerMobileNumber: '',
      ownerEmail: '',
      subscriptionEndDate: ''
    });
    setEditedOutlet({
      name: '',
      ownerName: '',
      ownerMobileNumber: '',
      ownerEmail: '',
      address: '',
      zip_code: '',
      email:'',
      phone:'',
      city: '',
      state: '',
      country: 'india',
      category: 'restaurant',
      gstNumber: '',
      subscriptionEndDate: new Date(new Date().setMonth(new Date().getMonth() + 1)), 
      is_active: true,
      profile: null,
      originType:'external'
    });
    setDiffEditedOutlet({});
    console.log(editedOutlet);
    onClose();
  }

  const handleLogoImageChange = (e) => {
    const file = e.target.files[0];
  
    if (file) {
      // Validate the file extension
      const allowedExtensions = ['jpg', 'jpeg', 'png'];
      const fileExtension = file.name.split('.').pop().toLowerCase();
  
      if (allowedExtensions.includes(fileExtension)) {
        setEditedOutlet((prev) => ({ ...prev, "profile_photos_pet_pooja_task_manager": file }));
        setDiffEditedOutlet((prev) => ({ ...prev, "profile_photos_pet_pooja_task_manager": file }));
  
        // Generate a preview for the selected image
        const reader = new FileReader();
        reader.onloadend = () => {
            handleFieldChange("profile", reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert('Invalid file type. Please upload a valid image file.');
        e.target.value = ''; 
        //setEditedOutlet((prev) => ({ ...prev, logoImage: null }));
        //setLogoPreview('');
      }
    } else {
      // Reset the logoImage and preview if no file selected
      handleFieldChange("profile", null);
      //setEditedOutlet((prev) => ({ ...prev, logoImage: null }));
      //setLogoPreview('');
    }
  };

  const handleClearLogoImage = () => {
    handleFieldChange("profile", null);
    //setEditedOutlet((prev) => ({ ...prev, logoImage: null }));
    //setLogoPreview('');
  };

  const handleSave = () => {
    if (validateForm()) {
      if(isAdding) {
        onSave(editedOutlet);
      } else {
        if(Object.keys(editedOutlet).length > 0) {
          onSave(editedOutlet._id ,diffEditedOutlet);
        }
      }
      handleOnClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleOnClose} aria-labelledby="outlet-dialog-title" maxWidth="md" style={{ width: '80%', margin: 'auto' }}>
      <DialogTitle id="outlet-dialog-title">
        {isAdding ? 'Add New Outlet' : 'Edit Outlet Details'}
        <IconButton style={{ position: 'absolute', right: '8px', top: '8px' }} onClick={handleOnClose}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Divider textAlign="center">OUTLET DETAILS</Divider>
        <TextField
          label="Outlet Name"
          value={editedOutlet.name}
          onChange={(e) => handleFieldChange('name', e.target.value)}
          variant="outlined"
          error={!!errors.name}
          helperText={errors.name}
          style={{margin : '5px'}}
        />
        <TextField
            label="Outlet Phone Number"
            value={editedOutlet.phone || ''}
            onChange={(e) => handleFieldChange('phone', e.target.value)}
            variant="outlined"
            error={!!errors.phone}
            helperText={errors.phone}
            style={{margin : '5px'}}
          />
        <TextField
            label="Outlet Email"
            value={editedOutlet.email || ''}
            onChange={(e) => handleFieldChange('email', e.target.value)}
            variant="outlined"
            error={!!errors.email}
            helperText={errors.email}
            style={{margin : '5px'}}
          />
          <TextField
            label="Outlet Address"
            value={editedOutlet.address || ''}
            onChange={(e) => handleFieldChange('address', e.target.value)}
            variant="outlined"
            style={{margin : '5px'}}
          />
          <TextField
            label="Pincode"
            value={editedOutlet.zip_code || ''}
            onChange={(e) => handleFieldChange('zip_code', e.target.value)}
            variant="outlined"
            error={!!errors.zip_code}
            helperText={errors.zip_code}
            style={{margin : '5px'}}
          />
          <TextField
            label="City"
            value={editedOutlet.city || ''}
            onChange={(e) => handleFieldChange('city', e.target.value)}
            variant="outlined"
            error={!!errors.city}
            helperText={errors.city}
            style={{margin : '5px'}}  
          />
          <TextField
            label="State"
            value={editedOutlet.state || ''}
            onChange={(e) => handleFieldChange('state', e.target.value)}
            variant="outlined"
            error={!!errors.state}
            helperText={errors.state}
            style={{margin : '5px'}}
          />
          <TextField
            label="GST Number"
            value={editedOutlet.gstNumber || ''}
            onChange={(e) => handleFieldChange('gstNumber', e.target.value)}
            variant="outlined"
            style={{margin : '5px'}}
          />
          <InputLabel id="industry-type-label">Industry Type</InputLabel>
          <Select
            labelId="industry-type-label"
            id="industry-type"
            value={editedOutlet.category}
            onChange={(e) => handleFieldChange('category', e.target.value)}
            style={{margin : '5px'}}
            input={<OutlinedInput />}
          >
            {industryTypes.map((type) => (
              <MenuItem key={type} value={type}>{type}</MenuItem>
            ))}
          </Select>
          <InputLabel htmlFor="logo">Logo</InputLabel> 
        <TextField
          type="file"
          onChange={handleLogoImageChange}
          variant="outlined"
          style={{margin : '5px'}}
        />
        {/* Logo preview and clear button */}
        {editedOutlet.profile && (
          <div>
            <img
              src={editedOutlet.profile}
              alt="Logo Preview"
              style={{ maxWidth: '100%', marginTop: '10px' }}
            />
            <Button
              onClick={handleClearLogoImage}
              color="secondary"
              variant="outlined"
              style={{ marginTop: '10px' }}
            >
              Clear Logo
            </Button>
          </div>
        )}
        <Divider textAlign="center">POC DETAILS</Divider>
        <TextField
            disabled = {isAdding ? false : true}
            label="POC Name"
            value={editedOutlet.ownerName || ''}
            onChange={(e) => handleFieldChange('ownerName', e.target.value)}
            variant="outlined"
            style={{margin : '5px'}}
        />
        <TextField
            label="POC Mobile Number"
            value={editedOutlet.ownerMobileNumber || ''}
            onChange={(e) => handleFieldChange('ownerMobileNumber', e.target.value)}
            variant="outlined"
            error={!!errors.ownerMobileNumber}
            helperText={errors.ownerMobileNumber}
            style={{margin : '5px'}}
          />
          <TextField
            disabled = {isAdding ? false : true}
            label="POC Email"
            value={editedOutlet.ownerEmail || ''}
            onChange={(e) => handleFieldChange('ownerEmail', e.target.value)}
            variant="outlined"
            error={!!errors.ownerEmail}
            helperText={errors.ownerEmail}
            style={{margin : '5px'}}
          />
          <Alert severity="warning">if points of contact exists, then email and name won't update</Alert>
          <Divider textAlign="center">ACTIONS</Divider>
          
          <InputLabel htmlFor="subscription-end-date">Subscription End Date</InputLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              //defaultValue = {editedOutlet.subscriptionEndDate}
              value={dayjs(new Date(editedOutlet.subscriptionEndDate).toLocaleDateString('en-US'))}
              onChange={(date) => handleFieldChange('subscriptionEndDate', date)}
              renderInput={(params) => <TextField {...params} label="Subscription End Date" fullWidth />}
              format="DD/MM/YYYY"
              slotProps={{
                textField: {
                  error: !!errors.subscriptionEndDate,
                  helperText: errors.subscriptionEndDate,
                },
              }}
              //error={!!errors.subscriptionEndDate}
              //helperText={errors.subscriptionEndDate}
              disablePast
              style={{margin : '5px'}}
            />
          </LocalizationProvider>
          <InputLabel id="is-active-label">Is Active</InputLabel>
          <Select
            labelId="is-active-label"
            id="is-active"
            value={editedOutlet.is_active}
            onChange={(e) => handleFieldChange('is_active', e.target.value)}
            input={<OutlinedInput />}
          >
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </Select>
          <InputLabel id="is-originType-label">Origin Type</InputLabel>

          <Select
            labelId="is-originType-label"
            id="is-outletType"
            value={editedOutlet.originType || "live"}
            onChange={(e) => handleFieldChange('originType', e.target.value)}
            input={<OutlinedInput />}
          >
            <MenuItem value={"petpooja demo"}>Petpooja Demo</MenuItem>
            <MenuItem value={"restaurant demo"}>Restaurant Demo</MenuItem>
            <MenuItem value={"live"}>Live</MenuItem>
            <MenuItem value={"id created"}>ID Created</MenuItem>
            <MenuItem value={"intenal"}>Internal</MenuItem>
            <MenuItem value={"external"}>external</MenuItem>
          </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          {isAdding ? 'Add Outlet' : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OutletDialog;
