import {  WEBADMIN_API_ENDPOINT, checkAPIResponse } from './apiConstants';
import { trimInputValue } from './utilities';
//var request = require('request');
const BASE_URL = process.env.REACT_APP_API_URL;

const API_URL = `${BASE_URL}${WEBADMIN_API_ENDPOINT}`;
// const USER_FILTER = `${BASE_URL}${USER_FILTER_ENDPOINT}`;

const webAdminApiService = {
  createWebAdmin: async (newItem) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      var formdata = new FormData();
      for (const key in newItem) {
        if (key !== "profileImage") {
          formdata.append(key, trimInputValue(newItem[key]));
        }
      }

      formdata.append('profileImage', '');
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          //'Content-Type': 'application/json',
          'x-access-token': accessToken,
        },
        body: formdata,
      });
      const data = await response.json();
      if (checkAPIResponse(data)) {
        console.log("Create WebAdmin API Response Successful!");
        return data;
      } else {
        console.error('Error: ', data.message);
        throw data.message;
      }
    } catch (error) {
      console.error('Error: ', error);
      throw error;
    }

  },

  getWebAdmin: async (page, pageSize) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch(`${API_URL}?page=${page}&pageSize=${pageSize}`, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': accessToken,
        },
      });
      const data = await response.json();
      if (checkAPIResponse(data)) {
        console.log("getWebAdmin API Response Successful!");
        return data;
      } else {
        console.error('Error fetching data:', data.message);
        throw data.message;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  },

  getWebAdminDashboardData: async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch(`${API_URL}/dashboard`, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': accessToken,
        },
      });
      const data = await response.json();
      if (checkAPIResponse(data)) {
        console.log("getWebAdmin API Response Successful!");
        return data;
      } else {
        console.error('Error fetching data:', data.message);
        throw data.message;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  },

  updateItem: async (itemId, updatedItem) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      var formdata = new FormData();
      for (const key in updatedItem) {
        if (key !== "profileImage") {
          formdata.append(key, trimInputValue(updatedItem[key]));
        }
      }
      formdata.append('profileImage', '');
      const response = await fetch(`${API_URL}/${itemId}`, {
        method: 'PUT',
        headers: {
          //'Content-Type': 'application/json',
          'x-access-token': accessToken,
        },
        body: formdata,
      });
      const data = await response.json();
      if (checkAPIResponse(data)) {
        console.log("Update WebAdmin API Response Successful!");
        return data;
      } else {
        console.error('Error: ', data.message);
        throw data.message;
      }
    } catch (error) {
      console.error('Error: ', error);
      throw error;
    }
  },

  deleteWebAdmin: async (itemId) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch(`${API_URL}/${itemId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': accessToken,
        },
      });

      const data = await response.json();

      if (checkAPIResponse(data)) {
        console.log("Deleted WebAdmin Successful!");
        const email = localStorage.getItem('email');
        if (email === data.results.data.email) {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('email');
          return "Login";
        }
        return data;
      } else {
        console.error('Error Deleting WebAdmin:', data.message);
        throw data.message;
      }
    } catch (error) {
      console.error('Error Deleting WebAdmin:', error);
      throw error;
    }
  },
};

export default webAdminApiService;