import React from 'react';
import { Link } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import ApartmentIcon from '@mui/icons-material/Apartment';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LogoutIcon from '@mui/icons-material/LogoutOutlined';
import authApiService from '../services/auth.apiservice';

const Sidebar = ({ drawerOpened, closeDrawer }) => {

  const handleListItemClick = () => {
    closeDrawer();
  };

  const handleLogoutClick = async () => {
    await authApiService.logout();
    closeDrawer();
  }

  return (
    <Drawer anchor="left" open={drawerOpened}>
      {/* <div
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        > */}
      <List>
        <ListItem button component={Link} to="/dashboard" onClick={handleListItemClick}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>

        <ListItem button component={Link} to="/hoPage" onClick={handleListItemClick}>
          <ListItemIcon>
            <ApartmentIcon />
          </ListItemIcon>
          <ListItemText primary="HO page" />
        </ListItem>

        <ListItem button component={Link} to="/users" onClick={handleListItemClick}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
        <ListItem button component={Link} to="/outlets" onClick={handleListItemClick}>
          <ListItemIcon>
            <RestaurantIcon />
          </ListItemIcon>
          <ListItemText primary="Outlets" />
        </ListItem>
        <ListItem button component={Link} to="/webadmins" onClick={handleListItemClick}>
          <ListItemIcon>
            <AdminPanelSettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Web Admins" />
        </ListItem>
        <ListItem button component={Link} to="/" onClick={handleLogoutClick}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
      <IconButton onClick={handleListItemClick}>
        <ChevronLeftIcon />
      </IconButton>
      {/* </div> */}
    </Drawer>
  );
};

export default Sidebar;