import { OUTLET_API_ENDPOINT, checkAPIResponse, OUTLET_GET_API_ENDPOINT, FILTER_ENDPOINT } from './apiConstants';
import { trimInputValue } from '../services/utilities';
const BASE_URL = process.env.REACT_APP_API_URL;

const API_URL = `${BASE_URL}${OUTLET_API_ENDPOINT}`;
const FILTER = `${BASE_URL}${FILTER_ENDPOINT}`;
const GET_API_URL = `${BASE_URL}${OUTLET_GET_API_ENDPOINT}`;

const outletApiService = {
  getAllOutlet: async (page, pageSize, field_key, value) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch(`${API_URL}?page=${page}&pageSize=${pageSize}&field_key=${field_key}&value=${value}`, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': accessToken,
        },
      });
      const data = await response.json();
      if (checkAPIResponse(data)) {
        console.log("getAllOutlets API Response Successful!");
        return data;
      } else {
        console.error('Error fetching data:', data.message);
        throw data.message;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  },

  getOutletById: async (id) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch(`${GET_API_URL}/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': accessToken,
        },
      });
      const data = await response.json();
      if (checkAPIResponse(data)) {
        console.log("getOutlet API Response Successful!");
        return data;
      } else {
        console.error('Error fetching data:', data.message);
        throw data.message;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  },

  deleteOutlet: async (itemId) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch(`${API_URL}?organizationId=${itemId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': accessToken,
        },
      });

      const data = await response.json();

      if (checkAPIResponse(data)) {
        console.log("Deleted Outlet Successful!");
        return data;
      } else {
        console.error('Error Deleting WebAdmin:', data.message);
        throw data.message;
      }
    } catch (error) {
      console.error('Error Deleting WebAdmin:', error);
      throw error;
    }
  },

  updateItem: async (itemId, updatedItem) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      var formdata = new FormData();
      for (const key in updatedItem) {
        // if (key !== "profile") {
        formdata.append(key, trimInputValue(updatedItem[key]));
        // }
      }
      // formdata.append('profile', '');
      formdata.append("organizationId", itemId);

      const response = await fetch(`${API_URL}`, {
        method: 'PUT',
        headers: {
          'x-access-token': accessToken,
        },
        body: formdata,
      });
      const data = await response.json();
      if (checkAPIResponse(data)) {
        console.log("Update WebAdmin API Response Successful!");
        return data;
      } else {
        console.error('Error: ', data.message);
        throw data.message;
      }
    } catch (error) {
      console.error('Error: ', error);
      throw error;
    }
  },

  createOutlet: async (newItem) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      var formdata = new FormData();
      for (const key in newItem) {
        console.log(key,newItem[key]);
        if (key !== "profile") {
          formdata.append(key, trimInputValue(newItem[key]));
        }
      }

      formdata.append('profile', '');
      console.log(newItem,API_URL);
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'x-access-token': accessToken,
        },
        body: formdata,
      });
      const data = await response.json();
      console.log(response);
      if (checkAPIResponse(data)) {
        console.log("Create Outlet API Response Successful!");
        return data;
      } else {
        console.error('Error: ', data.message);
        throw data.message;
      }
    } catch (error) {
      console.error('Error: ', error);
      throw error;
    }
  },

  outletFilter: async (newItem, page, pageSize) => {
    try {
      console.log(newItem);
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch(`${FILTER}/?page=${page}&pageSize=${pageSize}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': accessToken,
        },
        body: JSON.stringify(newItem),
      });
      const data = await response.json();
      if (checkAPIResponse(data)) {
        console.log("Outlet Filter API Response Successful!");
        return data;
      } else {
        console.error('Error: ', data.message);
        throw data.message;
      }
    } catch (error) {
      console.error('Error: ', error);
      throw error;
    }
  }
}

export default outletApiService;