import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const AlertDialog = ({ open, onClose, title, message, severity }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      {/* <DialogTitle id="alert-dialog-title">{title}</DialogTitle> */}
      <DialogContent>
        {severity ? (
          <Alert severity={severity}>
            <AlertTitle>{title}</AlertTitle>
            {message}
          </Alert>
        ) : (
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        )}
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Close
        </Button>
      </DialogActions> */}
    </Dialog>
  );
};

export default AlertDialog;
