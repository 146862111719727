import React, { useState, useEffect } from 'react';
import { Select, MenuItem, OutlinedInput } from '@material-ui/core';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TextField from '@mui/material/TextField';

import countriesData from './countryJson.json'; 
function CountrySelector() {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [countryOptions, setCountryOptions] = useState([]);

  useEffect(() => {

    try {
      setCountryOptions(countriesData);
    } catch (error) {
      console.error('Error parsing countries data:', error);
    }
  }, []);

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    setSelectedState('');
    setSelectedCity('');
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setSelectedCity('');
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  useEffect(() => {
    try {
      const parsedData = JSON.parse(countriesData);
      console.log('Parsed countries data:', parsedData);
      setCountryOptions(parsedData);
    } catch (error) {
      console.error('Error parsing countries data:', error);
    }
  }, []);
  const [hasPetpoojaId, setHasPetpoojaId] = useState(false);
  const [petpoojaId, setPetpoojaId] = useState('');

  const handleToggle = (event, newValue) => {
    setHasPetpoojaId(newValue === 'yes');
  };

  const handlePetpoojaIdChange = (event) => {
    setPetpoojaId(event.target.value);
  };

  return (
    <div>
           <Select
        labelId="country-label"
        id="country"
        value={selectedCountry}
        onChange={handleCountryChange}
        input={<OutlinedInput />}
      >
        <MenuItem value="">Select Country</MenuItem>
        {countryOptions.map(country => (
          <MenuItem key={country.id} value={country.name}>{country.name}</MenuItem>
        ))}
      </Select>

      {selectedCountry && (
        <Select
          labelId="state-label"
          id="state"
          value={selectedState}
          onChange={handleStateChange}
          input={<OutlinedInput />}
        >
          <MenuItem value="">Select State</MenuItem>
          {countryOptions.find(country => country.name === selectedCountry)?.state.map(state => (
            <MenuItem key={state.id} value={state.name}>{state.name}</MenuItem>
          ))}
        </Select>
      )}

      {selectedState && (
        <Select
          labelId="city-label"
          id="city"
          value={selectedCity}
          onChange={handleCityChange}
          input={<OutlinedInput />}
        >
          <MenuItem value="">Select City</MenuItem>
          {countryOptions
            .find(country => country.name === selectedCountry)
            ?.state.find(state => state.name === selectedState)
            ?.city.map(city => (
              <MenuItem key={city.id} value={city.name}>{city.name}</MenuItem>
            ))}
        </Select>
      )}

<span>Do you have a Petpooja ID?</span>

<ToggleButtonGroup
        color="primary"
        value={hasPetpoojaId ? 'yes' : 'no'}
        exclusive
        onChange={handleToggle}
        aria-label="Do you have a Petpooja ID?"
      >
        <ToggleButton value="yes">Yes</ToggleButton>
        <ToggleButton value="no">No</ToggleButton>
      </ToggleButtonGroup>
      {hasPetpoojaId && (
        <TextField
          id="petpooja-id"
          label="Petpooja ID"
          value={petpoojaId}
          onChange={handlePetpoojaIdChange}
          fullWidth
        />
      )}

    </div>
  );
}

export default CountrySelector;
