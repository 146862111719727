import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import { useState } from 'react';
import Sidebar from './Sidebar';

const Appbar = ({ title }) => {
    const [open , setOpen] = useState(false);

    const closeSidebar = () => {
        setOpen(false);
    };

    const toggleSidebar = () => {
        setOpen(!open);
    }

    return (
        <div className="Appbar">
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                    size="Medium"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={toggleSidebar}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    {/* <Button color="inherit">Login</Button> */}
                </Toolbar>
            </AppBar>
            <Sidebar
                drawerOpened={open}
                closeDrawer={closeSidebar}
            />
        </div>
    );
};
export default Appbar;